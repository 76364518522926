

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600&family=Rubik:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&display=swap');


@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css");

 :root {
    --bs-blue: #3b7ddd;
    --bs-indigo: #0a0a0a;
    --bs-purple: #6f42c1;
    --bs-pink: #e83e8c;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #fcb92c;
    --bs-green: #1cbb8c;
    --bs-teal: #20c997;
    --bs-cyan: #17a2b8;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #3b7ddd;
    --bs-secondary: #6c757d;
    --bs-success: #1cbb8c;
    --bs-info: #17a2b8;
    --bs-warning: #fcb92c;
    --bs-danger: #dc3545;
    --bs-light: #f5f7fb;
    --bs-dark: #212529;
    --bs-primary-rgb: 59, 125, 221;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 28, 187, 140;
    --bs-info-rgb: 23, 162, 184;
    --bs-warning-rgb: 252, 185, 44;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 245, 247, 251;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 73, 80, 87;
    --bs-body-bg-rgb: 245, 247, 251;
    --bs-font-sans-serif: "Rubik", "Helvetica Neue", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 0.875rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #495057;
    --bs-body-bg: #f5f7fb;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.2rem;
    --bs-border-radius-sm: 0.1rem;
    --bs-border-radius-lg: 0.3rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #3b7ddd;
    --bs-link-hover-color: #2f64b1;
    --bs-code-color: #e83e8c;
    --bs-highlight-bg: #fef1d5;
    --bs-nav-link-color:#111;
}

*,
:after,
:before {
    box-sizing: border-box
}

@media(prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    margin: 0;
    text-align: var(--bs-body-text-align)
}

hr {
    border: 0;
    border-top: 1px solid;
    color: inherit;
    margin: 1rem 0;
    opacity: .25
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: .5rem;
    margin-top: 0
}

.h1,
h1 {
    font-size: 1.75rem
}

.h2,
h2 {
    font-size: 1.53125rem
}

.h3,
h3 {
    font-size: 1.3125rem
}

.h4,
h4 {
    font-size: 1.09375rem
}

.h5,
.h6,
h5,
h6 {
    font-size: .875rem
}

p {
    margin-bottom: 1rem;
    margin-top: 0
}

abbr[title] {
    cursor: help;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1rem
}

ol,
ul {
    padding-left: 2rem
}

dl,
ol,
ul {
    margin-bottom: 1rem;
    margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 600
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

.small,
small {
    font-size: 80%
}

.mark,
mark {
    background-color: var(--bs-highlight-bg);
    padding: .1875em
}

sub,
sup {
    font-size: .75em;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: var(--bs-link-color);
    text-decoration: none
}

a:hover {
    color: var(--bs-link-hover-color);
    text-decoration: underline
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em
}

pre {
    display: block;
    font-size: 80%;
    margin-bottom: 1rem;
    margin-top: 0;
    overflow: auto
}

pre code {
    color: inherit;
    font-size: inherit;
    word-break: normal
}

code {
    word-wrap: break-word;
    color: var(--bs-code-color);
    font-size: 80%
}

a>code {
    color: inherit
}

kbd {
    background-color: var(--bs-body-color);
    border-radius: .1rem;
    color: var(--bs-body-bg);
    font-size: 80%;
    padding: .1875rem .375rem
}

kbd kbd {
    font-size: 1em;
    padding: 0
}

figure {
    margin: 0 0 1rem
}

img,
svg {
    vertical-align: middle
}

table {
    border-collapse: collapse;
    caption-side: bottom
}

caption {
    color: #6c757d;
    padding-bottom: .75rem;
    padding-top: .75rem;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border: 0 solid;
    border-color: inherit
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none !important
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    border-style: none;
    padding: 0
}

textarea {
    resize: vertical
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0
}

legend {
    float: left;
    font-size: 1.5rem;
    line-height: inherit;
    margin-bottom: .5rem;
    padding: 0;
    width: 100%
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

::file-selector-button {
    -webkit-appearance: button;
    font: inherit
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    cursor: pointer;
    display: list-item
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem
}

.display-1,
.display-2 {
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem
}

.display-3 {
    font-size: 4.5rem
}

.display-3,
.display-4 {
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem
}

.display-5 {
    font-size: 3rem
}

.display-5,
.display-6 {
    font-weight: 300;
    line-height: 1.2
}

.display-6 {
    font-size: 2.5rem
}

.list-inline,
.list-unstyled {
    list-style: none;
    padding-left: 0
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 80%;
    text-transform: uppercase
}

.blockquote {
    font-size: 1.09375rem;
    margin-bottom: 1rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    color: #6c757d;
    font-size: 80%;
    margin-bottom: 1rem;
    margin-top: -1rem
}

.blockquote-footer:before {
    content: "— "
}

.img-fluid,
.img-thumbnail {
    height: auto;
    max-width: 100%
}

.img-thumbnail {
    background-color: #f5f7fb;
    border: 1px solid var(--bs-border-color);
    border-radius: .2rem;
    padding: .25rem
}

.figure {
    display: inline-block
}

.figure-img {
    line-height: 1;
    margin-bottom: .5rem
}

.figure-caption {
    color: #6c757d;
    font-size: 80%
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    --bs-gutter-x: 0.75rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
    width: 100%
}

@media(min-width:576px) {
    .container,
    .container-sm {
        max-width: 540px
    }
}

@media(min-width:768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media(min-width:992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media(min-width:1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1200px
    }
}

.row {
    --bs-gutter-x: 24px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--bs-gutter-x)*-.5);
    margin-right: calc(var(--bs-gutter-x)*-.5);
    margin-top: calc(var(--bs-gutter-y)*-1)
}

.row>* {
    flex-shrink: 0;
    margin-top: var(--bs-gutter-y);
    max-width: 100%;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
    width: 100%
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,
.gy-5 {
    --bs-gutter-y: 3rem
}

.g-6,
.gx-6 {
    --bs-gutter-x: 4.5rem
}

.g-6,
.gy-6 {
    --bs-gutter-y: 4.5rem
}

.g-7,
.gx-7 {
    --bs-gutter-x: 6rem
}

.g-7,
.gy-7 {
    --bs-gutter-y: 6rem
}

@media(min-width:576px) {
    .col-sm {
        flex: 1 0 0%
    }
    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-sm-0 {
        margin-left: 0
    }
    .offset-sm-1 {
        margin-left: 8.33333333%
    }
    .offset-sm-2 {
        margin-left: 16.66666667%
    }
    .offset-sm-3 {
        margin-left: 25%
    }
    .offset-sm-4 {
        margin-left: 33.33333333%
    }
    .offset-sm-5 {
        margin-left: 41.66666667%
    }
    .offset-sm-6 {
        margin-left: 50%
    }
    .offset-sm-7 {
        margin-left: 58.33333333%
    }
    .offset-sm-8 {
        margin-left: 66.66666667%
    }
    .offset-sm-9 {
        margin-left: 75%
    }
    .offset-sm-10 {
        margin-left: 83.33333333%
    }
    .offset-sm-11 {
        margin-left: 91.66666667%
    }
    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0
    }
    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0
    }
    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem
    }
    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem
    }
    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem
    }
    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem
    }
    .g-sm-6,
    .gx-sm-6 {
        --bs-gutter-x: 4.5rem
    }
    .g-sm-6,
    .gy-sm-6 {
        --bs-gutter-y: 4.5rem
    }
    .g-sm-7,
    .gx-sm-7 {
        --bs-gutter-x: 6rem
    }
    .g-sm-7,
    .gy-sm-7 {
        --bs-gutter-y: 6rem
    }
}

@media(min-width:768px) {
    .col-md {
        flex: 1 0 0%
    }
    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-md-0 {
        margin-left: 0
    }
    .offset-md-1 {
        margin-left: 8.33333333%
    }
    .offset-md-2 {
        margin-left: 16.66666667%
    }
    .offset-md-3 {
        margin-left: 25%
    }
    .offset-md-4 {
        margin-left: 33.33333333%
    }
    .offset-md-5 {
        margin-left: 41.66666667%
    }
    .offset-md-6 {
        margin-left: 50%
    }
    .offset-md-7 {
        margin-left: 58.33333333%
    }
    .offset-md-8 {
        margin-left: 66.66666667%
    }
    .offset-md-9 {
        margin-left: 75%
    }
    .offset-md-10 {
        margin-left: 83.33333333%
    }
    .offset-md-11 {
        margin-left: 91.66666667%
    }
    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }
    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }
    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }
    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }
    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem
    }
    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem
    }
    .g-md-6,
    .gx-md-6 {
        --bs-gutter-x: 4.5rem
    }
    .g-md-6,
    .gy-md-6 {
        --bs-gutter-y: 4.5rem
    }
    .g-md-7,
    .gx-md-7 {
        --bs-gutter-x: 6rem
    }
    .g-md-7,
    .gy-md-7 {
        --bs-gutter-y: 6rem
    }
}

@media(min-width:992px) {
    .col-lg {
        flex: 1 0 0%
    }
    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-lg-0 {
        margin-left: 0
    }
    .offset-lg-1 {
        margin-left: 8.33333333%
    }
    .offset-lg-2 {
        margin-left: 16.66666667%
    }
    .offset-lg-3 {
        margin-left: 25%
    }
    .offset-lg-4 {
        margin-left: 33.33333333%
    }
    .offset-lg-5 {
        margin-left: 41.66666667%
    }
    .offset-lg-6 {
        margin-left: 50%
    }
    .offset-lg-7 {
        margin-left: 58.33333333%
    }
    .offset-lg-8 {
        margin-left: 66.66666667%
    }
    .offset-lg-9 {
        margin-left: 75%
    }
    .offset-lg-10 {
        margin-left: 83.33333333%
    }
    .offset-lg-11 {
        margin-left: 91.66666667%
    }
    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }
    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }
    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }
    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }
    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem
    }
    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
    .g-lg-6,
    .gx-lg-6 {
        --bs-gutter-x: 4.5rem
    }
    .g-lg-6,
    .gy-lg-6 {
        --bs-gutter-y: 4.5rem
    }
    .g-lg-7,
    .gx-lg-7 {
        --bs-gutter-x: 6rem
    }
    .g-lg-7,
    .gy-lg-7 {
        --bs-gutter-y: 6rem
    }
}

@media(min-width:1200px) {
    .col-xl {
        flex: 1 0 0%
    }
    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-xl-0 {
        margin-left: 0
    }
    .offset-xl-1 {
        margin-left: 8.33333333%
    }
    .offset-xl-2 {
        margin-left: 16.66666667%
    }
    .offset-xl-3 {
        margin-left: 25%
    }
    .offset-xl-4 {
        margin-left: 33.33333333%
    }
    .offset-xl-5 {
        margin-left: 41.66666667%
    }
    .offset-xl-6 {
        margin-left: 50%
    }
    .offset-xl-7 {
        margin-left: 58.33333333%
    }
    .offset-xl-8 {
        margin-left: 66.66666667%
    }
    .offset-xl-9 {
        margin-left: 75%
    }
    .offset-xl-10 {
        margin-left: 83.33333333%
    }
    .offset-xl-11 {
        margin-left: 91.66666667%
    }
    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }
    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }
    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }
    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }
    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem
    }
    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
    .g-xl-6,
    .gx-xl-6 {
        --bs-gutter-x: 4.5rem
    }
    .g-xl-6,
    .gy-xl-6 {
        --bs-gutter-y: 4.5rem
    }
    .g-xl-7,
    .gx-xl-7 {
        --bs-gutter-x: 6rem
    }
    .g-xl-7,
    .gy-xl-7 {
        --bs-gutter-y: 6rem
    }
}

@media(min-width:1440px) {
    .col-xxl {
        flex: 1 0 0%
    }
    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-xxl-0 {
        margin-left: 0
    }
    .offset-xxl-1 {
        margin-left: 8.33333333%
    }
    .offset-xxl-2 {
        margin-left: 16.66666667%
    }
    .offset-xxl-3 {
        margin-left: 25%
    }
    .offset-xxl-4 {
        margin-left: 33.33333333%
    }
    .offset-xxl-5 {
        margin-left: 41.66666667%
    }
    .offset-xxl-6 {
        margin-left: 50%
    }
    .offset-xxl-7 {
        margin-left: 58.33333333%
    }
    .offset-xxl-8 {
        margin-left: 66.66666667%
    }
    .offset-xxl-9 {
        margin-left: 75%
    }
    .offset-xxl-10 {
        margin-left: 83.33333333%
    }
    .offset-xxl-11 {
        margin-left: 91.66666667%
    }
    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }
    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }
    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }
    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }
    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }
    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
    .g-xxl-6,
    .gx-xxl-6 {
        --bs-gutter-x: 4.5rem
    }
    .g-xxl-6,
    .gy-xxl-6 {
        --bs-gutter-y: 4.5rem
    }
    .g-xxl-7,
    .gx-xxl-7 {
        --bs-gutter-x: 6rem
    }
    .g-xxl-7,
    .gy-xxl-7 {
        --bs-gutter-y: 6rem
    }
}

.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: #f8f9fa;
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.038);
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color);
    margin-bottom: 1rem;
    vertical-align: top;
    width: 100%
}

.table>:not(caption)>*>* {
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    padding: .75rem
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table-group-divider {
    border-top: 2px solid
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .3rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped-columns>:not(caption)>tr>:nth-child(2n),
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-color: #000;
    --bs-table-bg: #d8e5f8;
    --bs-table-border-color: #c2cedf;
    --bs-table-striped-bg: #cddaec;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c2cedf;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c8d4e5;
    --bs-table-hover-color: #000
}

.table-primary,
.table-secondary {
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color)
}

.table-secondary {
    --bs-table-color: #000;
    --bs-table-bg: #e2e3e5;
    --bs-table-border-color: #cbccce;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000
}

.table-success {
    --bs-table-color: #000;
    --bs-table-bg: #d2f1e8;
    --bs-table-border-color: #bdd9d1;
    --bs-table-striped-bg: #c8e5dc;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bdd9d1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c2dfd7;
    --bs-table-hover-color: #000
}

.table-info,
.table-success {
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color)
}

.table-info {
    --bs-table-color: #000;
    --bs-table-bg: #d1ecf1;
    --bs-table-border-color: #bcd4d9;
    --bs-table-striped-bg: #c7e0e5;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd4d9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1dadf;
    --bs-table-hover-color: #000
}

.table-warning {
    --bs-table-color: #000;
    --bs-table-bg: #fef1d5;
    --bs-table-border-color: #e5d9c0;
    --bs-table-striped-bg: #f1e5ca;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e5d9c0;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ebdfc5;
    --bs-table-hover-color: #000
}

.table-danger,
.table-warning {
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color)
}

.table-danger {
    --bs-table-color: #000;
    --bs-table-bg: #f8d7da;
    --bs-table-border-color: #dfc2c4;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000
}

.table-light {
    --bs-table-color: #000;
    --bs-table-bg: #f5f7fb;
    --bs-table-border-color: #dddee2;
    --bs-table-striped-bg: #e9ebee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dddee2;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e3e4e8;
    --bs-table-hover-color: #000
}

.table-dark,
.table-light {
    border-color: var(--bs-table-border-color);
    color: var(--bs-table-color)
}

.table-dark {
    --bs-table-color: #fff;
    --bs-table-bg: #212529;
    --bs-table-border-color: #373b3e;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff
}

.table-responsive {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
}

@media(max-width:575.98px) {
    .table-responsive-sm {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

@media(max-width:767.98px) {
    .table-responsive-md {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

@media(max-width:991.98px) {
    .table-responsive-lg {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

@media(max-width:1199.98px) {
    .table-responsive-xl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

@media(max-width:1439.98px) {
    .table-responsive-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    font-size: inherit;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: calc(.3rem + 1px);
    padding-top: calc(.3rem + 1px)
}

.col-form-label-lg {
    font-size: .925rem;
    padding-bottom: calc(.4rem + 1px);
    padding-top: calc(.4rem + 1px)
}

.col-form-label-sm {
    font-size: .75rem;
    padding-bottom: calc(.2rem + 1px);
    padding-top: calc(.2rem + 1px)
}

.form-text {
    color: #6c757d;
    font-size: 80%;
    margin-top: .25rem
}

.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .2rem;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .3rem .85rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%
}

@media(prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    background-color: #fff;
    border-color: #9dbeee;
    box-shadow: 0 0 0 .2rem rgba(59, 125, 221, .25);
    color: #495057;
    outline: 0
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    -webkit-margin-end: .85rem;
    background-color: #e9ecef;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: #495057;
    margin: -.3rem -.85rem;
    margin-inline-end: .85rem;
    padding: .3rem .85rem;
    pointer-events: none;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::file-selector-button {
    -webkit-margin-end: .85rem;
    background-color: #e9ecef;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: #495057;
    margin: -.3rem -.85rem;
    margin-inline-end: .85rem;
    padding: .3rem .85rem;
    pointer-events: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3
}

.form-control-plaintext {
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
    color: #495057;
    display: block;
    line-height: 1.5;
    margin-bottom: 0;
    padding: .3rem 0;
    width: 100%
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-left: 0;
    padding-right: 0
}

.form-control-sm {
    border-radius: .1rem;
    font-size: .75rem;
    min-height: calc(1.525rem + 2px);
    padding: .2rem .5rem
}

.form-control-sm::-webkit-file-upload-button {
    -webkit-margin-end: .5rem;
    margin: -.2rem -.5rem;
    margin-inline-end: .5rem;
    padding: .2rem .5rem
}

.form-control-sm::file-selector-button {
    -webkit-margin-end: .5rem;
    margin: -.2rem -.5rem;
    margin-inline-end: .5rem;
    padding: .2rem .5rem
}

.form-control-lg {
    border-radius: .3rem;
    font-size: .925rem;
    min-height: calc(2.1875rem + 2px);
    padding: .4rem 1rem
}

.form-control-lg::-webkit-file-upload-button {
    -webkit-margin-end: 1rem;
    margin: -.4rem -1rem;
    margin-inline-end: 1rem;
    padding: .4rem 1rem
}

.form-control-lg::file-selector-button {
    -webkit-margin-end: 1rem;
    margin: -.4rem -1rem;
    margin-inline-end: 1rem;
    padding: .4rem 1rem
}

textarea.form-control {
    min-height: calc(1.9125rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.525rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(2.1875rem + 2px)
}

.form-control-color {
    height: calc(1.9125rem + 2px);
    padding: .3rem;
    width: 3rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: .2rem
}

.form-control-color::-webkit-color-swatch {
    border-radius: .2rem
}

.form-control-color.form-control-sm {
    height: calc(1.525rem + 2px)
}

.form-control-color.form-control-lg {
    height: calc(2.1875rem + 2px)
}

.form-select {
    -moz-padding-start: calc(.85rem - 3px);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-position: right .85rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .2rem;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .3rem 2.55rem .3rem .85rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%
}

@media(prefers-reduced-motion:reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: #9dbeee;
    box-shadow: 0 0 0 .2rem rgba(59, 125, 221, .25);
    outline: 0
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    background-image: none;
    padding-right: .85rem
}

.form-select:disabled {
    background-color: #e9ecef
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057
}

.form-select-sm {
    border-radius: .1rem;
    font-size: .75rem;
    padding-bottom: .2rem;
    padding-left: .5rem;
    padding-top: .2rem
}

.form-select-lg {
    border-radius: .3rem;
    font-size: .925rem;
    padding-bottom: .4rem;
    padding-left: 1rem;
    padding-top: .4rem
}

.form-check {
    display: block;
    margin-bottom: .125rem;
    min-height: 1.3125rem;
    padding-left: 1.5em
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-reverse {
    padding-left: 0;
    padding-right: 1.5em;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: -1.5em
}

.form-check-input {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    color-adjust: exact;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    width: 1em
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #9dbeee;
    box-shadow: 0 0 0 .2rem rgba(59, 125, 221, .25);
    outline: 0
}

.form-check-input:checked {
    background-color: #3b7ddd;
    border-color: #3b7ddd
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #3b7ddd;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
    border-color: #3b7ddd
}

.form-check-input:disabled {
    filter: none;
    opacity: .5;
    pointer-events: none
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
    background-position: 0;
    border-radius: 2em;
    margin-left: -2.5em;
    transition: background-position .15s ease-in-out;
    width: 2em
}

@media(prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%239dbeee'/%3E%3C/svg%3E")
}

.form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    background-position: 100%
}

.form-switch.form-check-reverse {
    padding-left: 0;
    padding-right: 2.5em
}

.form-switch.form-check-reverse .form-check-input {
    margin-left: 0;
    margin-right: -2.5em
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    position: absolute
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
    filter: none;
    opacity: .65;
    pointer-events: none
}

.form-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    height: 1.4rem;
    padding: 0;
    width: 100%
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 .2rem rgba(59, 125, 221, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 .2rem rgba(59, 125, 221, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #3b7ddd;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}

@media(prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #c4d8f5
}

.form-range::-webkit-slider-runnable-track {
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.form-range::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    background-color: #3b7ddd;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}

@media(prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #c4d8f5
}

.form-range::-moz-range-track {
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.form-floating {
    position: relative
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25
}

.form-floating>label {
    border: 1px solid transparent;
    height: 100%;
    left: 0;
    overflow: hidden;
    padding: 1rem .85rem;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    white-space: nowrap;
    width: 100%
}

@media(prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
    padding: 1rem .85rem
}

.form-floating>.form-control-plaintext::-webkit-input-placeholder,
.form-floating>.form-control::-webkit-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::-moz-placeholder,
.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::placeholder,
.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown),
.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}

.form-floating>.form-control-plaintext:-webkit-autofill,
.form-floating>.form-control:-webkit-autofill {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}

.form-floating>.form-select {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: 1px 0
}

.input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-floating,
.input-group>.form-select {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 1%
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    align-items: center;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .2rem;
    color: #495057;
    display: flex;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .3rem .85rem;
    text-align: center;
    white-space: nowrap
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    border-radius: .3rem;
    font-size: .925rem;
    padding: .4rem 1rem
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
    border-radius: .1rem;
    font-size: .75rem;
    padding: .2rem .5rem
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 3.4rem
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select,
.input-group>:not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px
}

.valid-feedback {
    color: #1cbb8c;
    display: none;
    font-size: 80%;
    margin-top: .25rem;
    width: 100%
}

.valid-tooltip {
    background-color: rgba(28, 187, 140, .9);
    border-radius: .2rem;
    color: #000;
    display: none;
    font-size: .75rem;
    margin-top: .1rem;
    max-width: 100%;
    padding: .25rem .5rem;
    position: absolute;
    top: 100%;
    z-index: 5
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #1cbb8c
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #1cbb8c;
    box-shadow: 0 0 0 .2rem rgba(28, 187, 140, .25)
}

.form-select.is-valid,
.was-validated .form-select:valid {
    border-color: #1cbb8c
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
    border-color: #1cbb8c;
    box-shadow: 0 0 0 .2rem rgba(28, 187, 140, .25)
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: #1cbb8c
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #1cbb8c
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .2rem rgba(28, 187, 140, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #1cbb8c
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
    z-index: 1
}

.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
    z-index: 3
}

.invalid-feedback {
    color: #dc3545;
    display: none;
    font-size: 80%;
    margin-top: .25rem;
    width: 100%
}

.invalid-tooltip {
    background-color: rgba(220, 53, 69, .9);
    border-radius: .2rem;
    color: #fff;
    display: none;
    font-size: .75rem;
    margin-top: .1rem;
    max-width: 100%;
    padding: .25rem .5rem;
    position: absolute;
    top: 100%;
    z-index: 5
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
    border-color: #dc3545
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: #dc3545
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: #dc3545
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
    z-index: 2
}

.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
    z-index: 3
}

.btn {
    --bs-btn-padding-x: 0.85rem;
    --bs-btn-padding-y: 0.3rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #495057;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.2rem;
    --bs-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    background-color: var(--bs-btn-bg);
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    color: var(--bs-btn-color);
    cursor: pointer;
    display: inline-block;
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle
}

@media(prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    text-decoration: none
}

.btn-check:focus+.btn,
.btn:focus,
.btn:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    color: var(--bs-btn-hover-color)
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: var(--bs-btn-focus-box-shadow);
    outline: 0
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    color: var(--bs-btn-active-color)
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    color: var(--bs-btn-disabled-color);
    opacity: var(--bs-btn-disabled-opacity);
    pointer-events: none
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #3b7ddd;
    --bs-btn-border-color: #3b7ddd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #326abc;
    --bs-btn-hover-border-color: #2f64b1;
    --bs-btn-focus-shadow-rgb: 88, 145, 226;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2f64b1;
    --bs-btn-active-border-color: #2c5ea6;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #3b7ddd;
    --bs-btn-disabled-border-color: #3b7ddd
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d
}

.btn-success {
    --bs-btn-color: #000;
    --bs-btn-bg: #1cbb8c;
    --bs-btn-border-color: #1cbb8c;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #3ec59d;
    --bs-btn-hover-border-color: #33c298;
    --bs-btn-focus-shadow-rgb: 24, 159, 119;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #49c9a3;
    --bs-btn-active-border-color: #33c298;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #1cbb8c;
    --bs-btn-disabled-border-color: #1cbb8c
}

.btn-info {
    --bs-btn-color: #fff;
    --bs-btn-bg: #17a2b8;
    --bs-btn-border-color: #17a2b8;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #148a9c;
    --bs-btn-hover-border-color: #128293;
    --bs-btn-focus-shadow-rgb: 58, 176, 195;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #128293;
    --bs-btn-active-border-color: #117a8a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #17a2b8;
    --bs-btn-disabled-border-color: #17a2b8
}

.btn-warning {
    --bs-btn-color: #000;
    --bs-btn-bg: #fcb92c;
    --bs-btn-border-color: #fcb92c;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fcc44c;
    --bs-btn-hover-border-color: #fcc041;
    --bs-btn-focus-shadow-rgb: 214, 157, 37;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fdc756;
    --bs-btn-active-border-color: #fcc041;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #fcb92c;
    --bs-btn-disabled-border-color: #fcb92c
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #dc3545;
    --bs-btn-border-color: #dc3545;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bb2d3b;
    --bs-btn-hover-border-color: #b02a37;
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b02a37;
    --bs-btn-active-border-color: #a52834;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc3545;
    --bs-btn-disabled-border-color: #dc3545
}

.btn-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #f5f7fb;
    --bs-btn-border-color: #f5f7fb;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d0d2d5;
    --bs-btn-hover-border-color: #c4c6c9;
    --bs-btn-focus-shadow-rgb: 208, 210, 213;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #c4c6c9;
    --bs-btn-active-border-color: #b8b9bc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f5f7fb;
    --bs-btn-disabled-border-color: #f5f7fb
}

.btn-dark {
    --bs-btn-color: #fff;
    --bs-btn-bg: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #424649;
    --bs-btn-hover-border-color: #373b3e;
    --bs-btn-focus-shadow-rgb: 66, 70, 73;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4d5154;
    --bs-btn-active-border-color: #373b3e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #212529;
    --bs-btn-disabled-border-color: #212529
}

.btn-outline-primary {
    --bs-btn-color: #3b7ddd;
    --bs-btn-border-color: #3b7ddd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3b7ddd;
    --bs-btn-hover-border-color: #3b7ddd;
    --bs-btn-focus-shadow-rgb: 59, 125, 221;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3b7ddd;
    --bs-btn-active-border-color: #3b7ddd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #3b7ddd;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #3b7ddd;
    --bs-gradient: none
}

.btn-outline-secondary {
    --bs-btn-color: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6c757d;
    --bs-btn-hover-border-color: #6c757d;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6c757d;
    --bs-btn-active-border-color: #6c757d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #6c757d;
    --bs-gradient: none
}

.btn-outline-success {
    --bs-btn-color: #1cbb8c;
    --bs-btn-border-color: #1cbb8c;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #1cbb8c;
    --bs-btn-hover-border-color: #1cbb8c;
    --bs-btn-focus-shadow-rgb: 28, 187, 140;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #1cbb8c;
    --bs-btn-active-border-color: #1cbb8c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #1cbb8c;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #1cbb8c;
    --bs-gradient: none
}

.btn-outline-info {
    --bs-btn-color: #17a2b8;
    --bs-btn-border-color: #17a2b8;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #17a2b8;
    --bs-btn-hover-border-color: #17a2b8;
    --bs-btn-focus-shadow-rgb: 23, 162, 184;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #17a2b8;
    --bs-btn-active-border-color: #17a2b8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #17a2b8;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #17a2b8;
    --bs-gradient: none
}

.btn-outline-warning {
    --bs-btn-color: #fcb92c;
    --bs-btn-border-color: #fcb92c;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fcb92c;
    --bs-btn-hover-border-color: #fcb92c;
    --bs-btn-focus-shadow-rgb: 252, 185, 44;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fcb92c;
    --bs-btn-active-border-color: #fcb92c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fcb92c;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fcb92c;
    --bs-gradient: none
}

.btn-outline-danger {
    --bs-btn-color: #dc3545;
    --bs-btn-border-color: #dc3545;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #dc3545;
    --bs-btn-hover-border-color: #dc3545;
    --bs-btn-focus-shadow-rgb: 220, 53, 69;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #dc3545;
    --bs-btn-active-border-color: #dc3545;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #dc3545;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #dc3545;
    --bs-gradient: none
}

.btn-outline-light {
    --bs-btn-color: #f5f7fb;
    --bs-btn-border-color: #f5f7fb;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #f5f7fb;
    --bs-btn-hover-border-color: #f5f7fb;
    --bs-btn-focus-shadow-rgb: 245, 247, 251;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f5f7fb;
    --bs-btn-active-border-color: #f5f7fb;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f5f7fb;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f5f7fb;
    --bs-gradient: none
}

.btn-outline-dark {
    --bs-btn-color: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #212529;
    --bs-btn-hover-border-color: #212529;
    --bs-btn-focus-shadow-rgb: 33, 37, 41;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #212529;
    --bs-btn-active-border-color: #212529;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212529;
    --bs-gradient: none
}

.btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 88, 145, 226;
    text-decoration: none
}

.btn-link:focus,
.btn-link:hover {
    text-decoration: underline
}

.btn-link:focus {
    color: var(--bs-btn-color)
}

.btn-link:hover {
    color: var(--bs-btn-hover-color)
}

.btn-group-lg>.btn,
.btn-lg {
    --bs-btn-padding-y: 0.4rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 0.925rem;
    --bs-btn-border-radius: 0.3rem
}

.btn-group-sm>.btn,
.btn-sm {
    --bs-btn-padding-y: 0.2rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.75rem;
    --bs-btn-border-radius: 0.1rem
}

.fade {
    transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media(prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    height: auto;
    transition: width .35s ease;
    width: 0
}

@media(prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle:after {
    border-bottom: 0;
    border-left: .3em solid transparent;
    border-right: .3em solid transparent;
    border-top: .3em solid;
    content: "";
    margin-left: .255em;
    vertical-align: .255em
}

.dropdown-toggle:empty:after {
    margin-left: 0
}

.dropdown-menu {
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 0.875rem;
    --bs-dropdown-color: #495057;
    --bs-dropdown-bg: #fff;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: 0.2rem;
    --bs-dropdown-border-width: 1px;
    --bs-dropdown-inner-border-radius: calc(0.2rem - 1px);
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
    --bs-dropdown-link-color: #212529;
    --bs-dropdown-link-hover-color: #1e2125;
    --bs-dropdown-link-hover-bg: #e9ecef;
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #3b7ddd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    background-clip: padding-box;
    background-color: var(--bs-dropdown-bg);
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
    color: var(--bs-dropdown-color);
    display: none;
    font-size: var(--bs-dropdown-font-size);
    list-style: none;
    margin: 0;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    position: absolute;
    text-align: left;
    z-index: 1000
}

.dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: var(--bs-dropdown-spacer);
    top: 100%
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    left: 0;
    right: auto
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    left: auto;
    right: 0
}

@media(min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }
    .dropdown-menu-sm-start[data-bs-popper] {
        left: 0;
        right: auto
    }
    .dropdown-menu-sm-end {
        --bs-position: end
    }
    .dropdown-menu-sm-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

@media(min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }
    .dropdown-menu-md-start[data-bs-popper] {
        left: 0;
        right: auto
    }
    .dropdown-menu-md-end {
        --bs-position: end
    }
    .dropdown-menu-md-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

@media(min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }
    .dropdown-menu-lg-start[data-bs-popper] {
        left: 0;
        right: auto
    }
    .dropdown-menu-lg-end {
        --bs-position: end
    }
    .dropdown-menu-lg-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

@media(min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }
    .dropdown-menu-xl-start[data-bs-popper] {
        left: 0;
        right: auto
    }
    .dropdown-menu-xl-end {
        --bs-position: end
    }
    .dropdown-menu-xl-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

@media(min-width:1440px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
        left: 0;
        right: auto
    }
    .dropdown-menu-xxl-end {
        --bs-position: end
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
        left: auto;
        right: 0
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    bottom: 100%;
    margin-bottom: var(--bs-dropdown-spacer);
    margin-top: 0;
    top: auto
}

.dropup .dropdown-toggle:after {
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
    border-right: .3em solid transparent;
    border-top: 0;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em
}

.dropup .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    left: 100%;
    margin-left: var(--bs-dropdown-spacer);
    margin-top: 0;
    right: auto;
    top: 0
}

.dropend .dropdown-toggle:after {
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
    border-right: 0;
    border-top: .3em solid transparent;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em
}

.dropend .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropend .dropdown-toggle:after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    left: auto;
    margin-right: var(--bs-dropdown-spacer);
    margin-top: 0;
    right: 100%;
    top: 0
}

.dropstart .dropdown-toggle:after {
    content: "";
    display: inline-block;
    display: none;
    margin-left: .255em;
    vertical-align: .255em
}

.dropstart .dropdown-toggle:before {
    border-bottom: .3em solid transparent;
    border-right: .3em solid;
    border-top: .3em solid transparent;
    content: "";
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em
}

.dropstart .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropstart .dropdown-toggle:before {
    vertical-align: 0
}

.dropdown-divider {
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    opacity: 1;
    overflow: hidden
}

.dropdown-item {
    background-color: transparent;
    border: 0;
    clear: both;
    color: var(--bs-dropdown-link-color);
    display: block;
    font-weight: 400;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    text-align: inherit;
    white-space: nowrap;
    width: 100%
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: var(--bs-dropdown-link-hover-bg);
    color: var(--bs-dropdown-link-hover-color);
    text-decoration: none
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--bs-dropdown-link-active-bg);
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    background-color: transparent;
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    color: var(--bs-dropdown-header-color);
    display: block;
    font-size: .75rem;
    margin-bottom: 0;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    white-space: nowrap
}

.dropdown-item-text {
    color: var(--bs-dropdown-link-color);
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)
}

.dropdown-menu-dark {
    --bs-dropdown-color: #dee2e6;
    --bs-dropdown-bg: #343a40;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-box-shadow: ;
    --bs-dropdown-link-color: #dee2e6;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-link-hover-bg: hsla(0, 0%, 100%, 0.15);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #3b7ddd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-header-color: #adb5bd
}

.btn-group,
.btn-group-vertical {
    display: inline-flex;
    position: relative;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    flex: 1 1 auto;
    position: relative
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group {
    border-radius: .2rem
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.dropdown-toggle-split {
    padding-left: .6375rem;
    padding-right: .6375rem
}

.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split:before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-left: .375rem;
    padding-right: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-left: .75rem;
    padding-right: .75rem
}

.btn-group-vertical {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: #333;
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.nav-link {
    color: var(--bs-nav-link-color);
    display: block;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,
.nav-link:hover {
    color: var(--bs-nav-link-hover-color);
    text-decoration: none
}

.nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    cursor: default;
    pointer-events: none
}

.nav-tabs {
    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: #dee2e6;
    --bs-nav-tabs-border-radius: 0.2rem;
    --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
    --bs-nav-tabs-link-active-color: #495057;
    --bs-nav-tabs-link-active-bg: #f5f7fb;
    --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #f5f7fb;
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color)
}

.nav-tabs .nav-link {
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
    margin-bottom: calc(var(--bs-nav-tabs-border-width)*-1)
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: var(--bs-nav-tabs-link-hover-border-color);
    isolation: isolate
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
    background-color: transparent;
    border-color: transparent;
    color: var(--bs-nav-link-disabled-color)
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #fff;
    border-color: var(--bs-nav-tabs-link-active-border-color);
    color: #000;
    font-weight:bold
}

.nav-tabs .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: calc(var(--bs-nav-tabs-border-width)*-1)
}

.nav-pills {
    --bs-nav-pills-border-radius: 0.2rem;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #3b7ddd
}

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius)
}

.nav-pills .nav-link:disabled {
    background-color: transparent;
    border-color: transparent;
    color: var(--bs-nav-link-disabled-color)
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--bs-nav-pills-link-active-bg);
    color: var(--bs-nav-pills-link-active-color)
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    --bs-navbar-padding-x: 1.375rem;
    --bs-navbar-padding-y: 0.875rem;
    --bs-navbar-color: rgba(0, 0, 0, 0.55);
    --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
    --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-padding-y: 0.875rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1.15rem;
    --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-nav-link-padding-x: 0.5rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --bs-navbar-toggler-padding-x: 0.75rem;
    --bs-navbar-toggler-font-size: 0.925rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --bs-navbar-toggler-border-radius: 0.2rem;
    --bs-navbar-toggler-focus-width: 0.2rem;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
    position: relative
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl {
    align-items: center;
    display: flex;
    flex-wrap: inherit;
    justify-content: space-between
}

.navbar-brand {
    color: var(--bs-navbar-brand-color);
    font-size: var(--bs-navbar-brand-font-size);
    margin-right: var(--bs-navbar-brand-margin-end);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    padding-top: var(--bs-navbar-brand-padding-y);
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    color: var(--bs-navbar-brand-hover-color);
    text-decoration: none
}

.navbar-nav {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: var(--bs-navbar-active-color)
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    color: var(--bs-navbar-color);
    padding-bottom: .5rem;
    padding-top: .5rem
}

.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
    color: var(--bs-navbar-active-color)
}

.navbar-collapse {
    align-items: center;
    flex-basis: 100%;
    flex-grow: 1
}

.navbar-toggler {
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    color: var(--bs-navbar-color);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    transition: var(--bs-navbar-toggler-transition)
}

@media(prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
    outline: 0;
    text-decoration: none
}

.navbar-toggler-icon {
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    height: 1.5em;
    vertical-align: middle;
    width: 1.5em
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media(min-width:576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }
    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-sm .navbar-toggler {
        display: none
    }
    .navbar-expand-sm .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }
    .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none
    }
    .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media(min-width:768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }
    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-md .navbar-toggler {
        display: none
    }
    .navbar-expand-md .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }
    .navbar-expand-md .offcanvas .offcanvas-header {
        display: none
    }
    .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media(min-width:992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }
    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-lg .navbar-toggler {
        display: none
    }
    .navbar-expand-lg .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }
    .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none
    }
    .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media(min-width:1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }
    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-xl .navbar-toggler {
        display: none
    }
    .navbar-expand-xl .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }
    .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none
    }
    .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media(min-width:1440px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-left: var(--bs-navbar-nav-link-padding-x);
        padding-right: var(--bs-navbar-nav-link-padding-x)
    }
    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-xxl .navbar-toggler {
        display: none
    }
    .navbar-expand-xxl .offcanvas {
        background-color: transparent !important;
        border: 0 !important;
        flex-grow: 1;
        height: auto !important;
        position: static;
        transform: none !important;
        transition: none;
        visibility: visible !important;
        width: auto !important;
        z-index: auto
    }
    .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none
    }
    .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x)
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto
}

.navbar-expand .offcanvas .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0
}

.navbar-dark {
    --bs-navbar-color: hsla(0, 0%, 100%, 0.55);
    --bs-navbar-hover-color: hsla(0, 0%, 100%, 0.75);
    --bs-navbar-disabled-color: hsla(0, 0%, 100%, 0.25);
    --bs-navbar-active-color: #fff;
    --bs-navbar-brand-color: #fff;
    --bs-navbar-brand-hover-color: #fff;
    --bs-navbar-toggler-border-color: hsla(0, 0%, 100%, 0.1);
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.card {
    --bs-card-spacer-y: 1.25rem;
    --bs-card-spacer-x: 1.25rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 0;
    --bs-card-border-color: transparent;
    --bs-card-border-radius: 0.25rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: 0.25rem;
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1.25rem;
    --bs-card-cap-bg: #fff;
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 12px;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    display: flex;
    flex-direction: column;
    height: var(--bs-card-height);
    min-width: 0;
    position: relative
}

.card>hr {
    margin-left: 0;
    margin-right: 0
}

.card>.list-group {
    border-bottom: inherit;
    border-top: inherit
}

.card>.list-group:first-child {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius);
    border-top-width: 0
}

.card>.list-group:last-child {
    border-bottom-left-radius: var(--bs-card-inner-border-radius);
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-width: 0
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    color: var(--bs-card-color);
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x)
}

.card-title {
    margin-bottom: var(--bs-card-title-spacer-y)
}

.card-subtitle {
    margin-top: calc(var(--bs-card-title-spacer-y)*-.5)
}

.card-subtitle,
.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: var(--bs-card-spacer-x)
}

.card-header {
    background-color: var(--bs-card-cap-bg);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
    color: #000;
    margin-bottom: 0;
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    border-bottom:1px solid #eee !important;
    font-size:1.2em;
    font-weight:bold
}

.card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0
}

.card-footer {
    background-color: var(--bs-card-cap-bg);
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
    color: var(--bs-card-cap-color);
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x)
}

.card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)
}

.card-header-tabs {
    border-bottom: 0;
    margin-bottom: calc(var(--bs-card-cap-padding-y)*-1);
    margin-left: calc(var(--bs-card-cap-padding-x)*-.5);
    margin-right: calc(var(--bs-card-cap-padding-x)*-.5)
}

.card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg)
}

.card-header-pills {
    margin-left: calc(var(--bs-card-cap-padding-x)*-.5);
    margin-right: calc(var(--bs-card-cap-padding-x)*-.5)
}

.card-img-overlay {
    border-radius: var(--bs-card-inner-border-radius);
    bottom: 0;
    left: 0;
    padding: var(--bs-card-img-overlay-padding);
    position: absolute;
    right: 0;
    top: 0
}

.card-img,
.card-img-bottom,
.card-img-top {
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card-img,
.card-img-bottom {
    border-bottom-left-radius: var(--bs-card-inner-border-radius);
    border-bottom-right-radius: var(--bs-card-inner-border-radius)
}

.card-group>.card {
    margin-bottom: var(--bs-card-group-margin)
}

@media(min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }
    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }
    .card-group>.card+.card {
        border-left: 0;
        margin-left: 0
    }
    .card-group>.card:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0
    }
    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }
    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }
    .card-group>.card:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0
    }
    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }
    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.badge {
    --bs-badge-padding-x: 0.45em;
    --bs-badge-padding-y: 0.3em;
    --bs-badge-font-size: 80%;
    --bs-badge-font-weight: 600;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: 0.2rem;
    border-radius: var(--bs-badge-border-radius);
    color: var(--bs-badge-color);
    display: inline-block;
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.list-group {
    --bs-list-group-color: #212529;
    --bs-list-group-bg: #fff;
    --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
    --bs-list-group-border-width: 1px;
    --bs-list-group-border-radius: 0.2rem;
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 0.5rem;
    --bs-list-group-action-color: #495057;
    --bs-list-group-action-hover-color: #495057;
    --bs-list-group-action-hover-bg: #f8f9fa;
    --bs-list-group-action-active-color: #495057;
    --bs-list-group-action-active-bg: #e9ecef;
    --bs-list-group-disabled-color: #6c757d;
    --bs-list-group-disabled-bg: #fff;
    --bs-list-group-active-color: #fff;
    --bs-list-group-active-bg: #3b7ddd;
    --bs-list-group-active-border-color: #3b7ddd;
    border-radius: var(--bs-list-group-border-radius);
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0
}

.list-group-numbered {
    counter-reset: section;
    list-style-type: none
}

.list-group-numbered>.list-group-item:before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    color: var(--bs-list-group-action-color);
    text-align: inherit;
    width: 100%
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    background-color: var(--bs-list-group-action-hover-bg);
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    z-index: 1
}

.list-group-item-action:active {
    background-color: var(--bs-list-group-action-active-bg);
    color: var(--bs-list-group-action-active-color)
}

.list-group-item {
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
    color: var(--bs-list-group-color);
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    position: relative
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    background-color: var(--bs-list-group-disabled-bg);
    color: var(--bs-list-group-disabled-color);
    pointer-events: none
}

.list-group-item.active {
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color);
    color: var(--bs-list-group-active-color);
    z-index: 2
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    border-top-width: var(--bs-list-group-border-width);
    margin-top: calc(var(--bs-list-group-border-width)*-1)
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius)
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width)
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width)*-1)
}

@media(min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }
    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    .list-group-horizontal-sm>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }
    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }
    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

@media(min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }
    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    .list-group-horizontal-md>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }
    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }
    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

@media(min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }
    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    .list-group-horizontal-lg>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }
    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }
    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

@media(min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }
    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    .list-group-horizontal-xl>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }
    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }
    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

@media(min-width:1440px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }
    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-list-group-border-radius)
    }
    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: var(--bs-list-group-border-width)
    }
    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        border-left-width: var(--bs-list-group-border-width);
        margin-left: calc(var(--bs-list-group-border-width)*-1)
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-bottom-width: var(--bs-list-group-border-width);
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    background-color: #d8e5f8;
    color: #234b85
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    background-color: #c2cedf;
    color: #234b85
}

.list-group-item-primary.list-group-item-action.active {
    background-color: #234b85;
    border-color: #234b85;
    color: #fff
}

.list-group-item-secondary {
    background-color: #e2e3e5;
    color: #41464b
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    background-color: #cbccce;
    color: #41464b
}

.list-group-item-secondary.list-group-item-action.active {
    background-color: #41464b;
    border-color: #41464b;
    color: #fff
}

.list-group-item-success {
    background-color: #d2f1e8;
    color: #117054
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    background-color: #bdd9d1;
    color: #117054
}

.list-group-item-success.list-group-item-action.active {
    background-color: #117054;
    border-color: #117054;
    color: #fff
}

.list-group-item-info {
    background-color: #d1ecf1;
    color: #0e616e
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    background-color: #bcd4d9;
    color: #0e616e
}

.list-group-item-info.list-group-item-action.active {
    background-color: #0e616e;
    border-color: #0e616e;
    color: #fff
}

.list-group-item-warning {
    background-color: #fef1d5;
    color: #976f1a
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    background-color: #e5d9c0;
    color: #976f1a
}

.list-group-item-warning.list-group-item-action.active {
    background-color: #976f1a;
    border-color: #976f1a;
    color: #fff
}

.list-group-item-danger {
    background-color: #f8d7da;
    color: #842029
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    background-color: #dfc2c4;
    color: #842029
}

.list-group-item-danger.list-group-item-action.active {
    background-color: #842029;
    border-color: #842029;
    color: #fff
}

.list-group-item-light {
    background-color: #fdfdfe;
    color: #626364
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    background-color: #e4e4e5;
    color: #626364
}

.list-group-item-light.list-group-item-action.active {
    background-color: #626364;
    border-color: #626364;
    color: #fff
}

.list-group-item-dark {
    background-color: #d3d3d4;
    color: #141619
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    background-color: #bebebf;
    color: #141619
}

.list-group-item-dark.list-group-item-action.active {
    background-color: #141619;
    border-color: #141619;
    color: #fff
}

.btn-close {
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: .2rem;
    box-sizing: content-box;
    color: #000;
    height: 1em;
    opacity: .5;
    padding: .25em;
    width: 1em
}

.btn-close:hover {
    color: #000;
    opacity: .75;
    text-decoration: none
}

.btn-close:focus {
    box-shadow: 0 0 0 .2rem rgba(59, 125, 221, .25);
    opacity: 1;
    outline: 0
}

.btn-close.disabled,
.btn-close:disabled {
    opacity: .25;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%)
}

.clearfix:after {
    clear: both;
    content: "";
    display: block
}

.text-bg-primary {
    background-color: RGBA(59, 125, 221, var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.text-bg-secondary {
    background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.text-bg-success {
    background-color: RGBA(28, 187, 140, var(--bs-bg-opacity, 1)) !important;
    color: #000 !important
}

.text-bg-info {
    background-color: RGBA(23, 162, 184, var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.text-bg-warning {
    background-color: RGBA(252, 185, 44, var(--bs-bg-opacity, 1)) !important;
    color: #000 !important
}

.text-bg-danger {
    background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.text-bg-light {
    background-color: RGBA(245, 247, 251, var(--bs-bg-opacity, 1)) !important;
    color: #000 !important
}

.text-bg-dark {
    background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
    color: #fff !important
}

.link-primary {
    color: #3b7ddd !important
}

.link-primary:focus,
.link-primary:hover {
    color: #2f64b1 !important
}

.link-secondary {
    color: #6c757d !important
}

.link-secondary:focus,
.link-secondary:hover {
    color: #565e64 !important
}

.link-success {
    color: #1cbb8c !important
}

.link-success:focus,
.link-success:hover {
    color: #49c9a3 !important
}

.link-info {
    color: #17a2b8 !important
}

.link-info:focus,
.link-info:hover {
    color: #128293 !important
}

.link-warning {
    color: #fcb92c !important
}

.link-warning:focus,
.link-warning:hover {
    color: #fdc756 !important
}

.link-danger {
    color: #dc3545 !important
}

.link-danger:focus,
.link-danger:hover {
    color: #b02a37 !important
}

.link-light {
    color: #f5f7fb !important
}

.link-light:focus,
.link-light:hover {
    color: #f7f9fc !important
}

.link-dark {
    color: #212529 !important
}

.link-dark:focus,
.link-dark:hover {
    color: #1a1e21 !important
}

.ratio {
    position: relative;
    width: 100%
}

.ratio:before {
    content: "";
    display: block;
    padding-top: var(--bs-aspect-ratio)
}

.ratio>* {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    top: 0
}

.fixed-bottom,
.fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1030
}

.fixed-bottom {
    bottom: 0
}

.sticky-top {
    top: 0
}

.sticky-bottom,
.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020
}

.sticky-bottom {
    bottom: 0
}

@media(min-width:576px) {
    .sticky-sm-top {
        top: 0
    }
    .sticky-sm-bottom,
    .sticky-sm-top {
        position: -webkit-sticky;
        position: sticky;
        z-index: 1020
    }
    .sticky-sm-bottom {
        bottom: 0
    }
}

@media(min-width:768px) {
    .sticky-md-top {
        top: 0
    }
    .sticky-md-bottom,
    .sticky-md-top {
        position: -webkit-sticky;
        position: sticky;
        z-index: 1020
    }
    .sticky-md-bottom {
        bottom: 0
    }
}

@media(min-width:992px) {
    .sticky-lg-top {
        top: 0
    }
    .sticky-lg-bottom,
    .sticky-lg-top {
        position: -webkit-sticky;
        position: sticky;
        z-index: 1020
    }
    .sticky-lg-bottom {
        bottom: 0
    }
}

@media(min-width:1200px) {
    .sticky-xl-top {
        top: 0
    }
    .sticky-xl-bottom,
    .sticky-xl-top {
        position: -webkit-sticky;
        position: sticky;
        z-index: 1020
    }
    .sticky-xl-bottom {
        bottom: 0
    }
}

@media(min-width:1440px) {
    .sticky-xxl-top {
        top: 0
    }
    .sticky-xxl-bottom,
    .sticky-xxl-top {
        position: -webkit-sticky;
        position: sticky;
        z-index: 1020
    }
    .sticky-xxl-bottom {
        bottom: 0
    }
}

.hstack {
    align-items: center;
    flex-direction: row
}

.hstack,
.vstack {
    align-self: stretch;
    display: flex
}

.vstack {
    flex: 1 1 auto;
    flex-direction: column
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    clip: rect(0, 0, 0, 0) !important;
    border: 0 !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important
}

.stretched-link:after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    align-self: stretch;
    background-color: currentcolor;
    display: inline-block;
    min-height: 1em;
    opacity: .25;
    width: 1px
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .05) !important
}

.shadow-sm {
    box-shadow: 0 .05rem .2rem rgba(0, 0, 0, .05) !important
}

.shadow-lg {
    box-shadow: 0 .2rem .2rem rgba(0, 0, 0, .05) !important
}

.shadow-none {
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
}

.border-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
}

.border-success {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
}

.border-info {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
}

.border-warning {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
}

.border-danger {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
}

.border-light {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
}

.border-dark {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
}

.border-white {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
}

.border-1 {
    --bs-border-width: 1px
}

.border-2 {
    --bs-border-width: 2px
}

.border-3 {
    --bs-border-width: 3px
}

.border-4 {
    --bs-border-width: 4px
}

.border-5 {
    --bs-border-width: 5px
}

.border-opacity-10 {
    --bs-border-opacity: 0.1
}

.border-opacity-25 {
    --bs-border-opacity: 0.25
}

.border-opacity-50 {
    --bs-border-opacity: 0.5
}

.border-opacity-75 {
    --bs-border-opacity: 0.75
}

.border-opacity-100 {
    --bs-border-opacity: 1
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-6 {
    margin: 4.5rem !important
}

.m-7 {
    margin: 6rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important
}

.mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
}

.mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
}

.mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
}

.mx-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
}

.mx-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important
}

.mx-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
}

.my-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important
}

.my-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important
}

.my-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important
}

.my-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important
}

.my-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important
}

.my-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important
}

.my-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important
}

.my-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important
}

.my-auto {
    margin-bottom: auto !important;
    margin-top: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-6 {
    margin-top: 4.5rem !important
}

.mt-7 {
    margin-top: 6rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-6 {
    margin-right: 4.5rem !important
}

.me-7 {
    margin-right: 6rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-6 {
    margin-bottom: 4.5rem !important
}

.mb-7 {
    margin-bottom: 6rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-6 {
    margin-left: 4.5rem !important
}

.ms-7 {
    margin-left: 6rem !important
}

.ms-auto {
    margin-left: auto !important
}

.m-n1 {
    margin: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.m-n5 {
    margin: -3rem !important
}

.m-n6 {
    margin: -4.5rem !important
}

.m-n7 {
    margin: -6rem !important
}

.mx-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important
}

.mx-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important
}

.mx-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
}

.mx-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important
}

.mx-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
}

.mx-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important
}

.mx-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important
}

.my-n1 {
    margin-bottom: -.25rem !important;
    margin-top: -.25rem !important
}

.my-n2 {
    margin-bottom: -.5rem !important;
    margin-top: -.5rem !important
}

.my-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important
}

.my-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important
}

.my-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important
}

.my-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important
}

.my-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important
}

.mt-n1 {
    margin-top: -.25rem !important
}

.mt-n2 {
    margin-top: -.5rem !important
}

.mt-n3 {
    margin-top: -1rem !important
}

.mt-n4 {
    margin-top: -1.5rem !important
}

.mt-n5 {
    margin-top: -3rem !important
}

.mt-n6 {
    margin-top: -4.5rem !important
}

.mt-n7 {
    margin-top: -6rem !important
}

.me-n1 {
    margin-right: -.25rem !important
}

.me-n2 {
    margin-right: -.5rem !important
}

.me-n3 {
    margin-right: -1rem !important
}

.me-n4 {
    margin-right: -1.5rem !important
}

.me-n5 {
    margin-right: -3rem !important
}

.me-n6 {
    margin-right: -4.5rem !important
}

.me-n7 {
    margin-right: -6rem !important
}

.mb-n1 {
    margin-bottom: -.25rem !important
}

.mb-n2 {
    margin-bottom: -.5rem !important
}

.mb-n3 {
    margin-bottom: -1rem !important
}

.mb-n4 {
    margin-bottom: -1.5rem !important
}

.mb-n5 {
    margin-bottom: -3rem !important
}

.mb-n6 {
    margin-bottom: -4.5rem !important
}

.mb-n7 {
    margin-bottom: -6rem !important
}

.ms-n1 {
    margin-left: -.25rem !important
}

.ms-n2 {
    margin-left: -.5rem !important
}

.ms-n3 {
    margin-left: -1rem !important
}

.ms-n4 {
    margin-left: -1.5rem !important
}

.ms-n5 {
    margin-left: -3rem !important
}

.ms-n6 {
    margin-left: -4.5rem !important
}

.ms-n7 {
    margin-left: -6rem !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.p-6 {
    padding: 4.5rem !important
}

.p-7 {
    padding: 6rem !important
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
}

.px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
}

.px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
}

.px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
}

.px-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
}

.px-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important
}

.px-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
}

.py-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important
}

.py-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important
}

.py-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important
}

.py-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important
}

.py-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important
}

.py-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important
}

.py-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important
}

.py-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pt-6 {
    padding-top: 4.5rem !important
}

.pt-7 {
    padding-top: 6rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pe-6 {
    padding-right: 4.5rem !important
}

.pe-7 {
    padding-right: 6rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.pb-6 {
    padding-bottom: 4.5rem !important
}

.pb-7 {
    padding-bottom: 6rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.ps-6 {
    padding-left: 4.5rem !important
}

.ps-7 {
    padding-left: 6rem !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 3rem !important
}

.gap-6 {
    gap: 4.5rem !important
}

.gap-7 {
    gap: 6rem !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: 1.75rem !important
}

.fs-2 {
    font-size: 1.53125rem !important
}

.fs-3 {
    font-size: 1.3125rem !important
}

.fs-4 {
    font-size: 1.09375rem !important
}

.fs-5,
.fs-6 {
    font-size: .875rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-bold,
.fw-semibold {
    font-weight: 600 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-base,
.lh-lg,
.lh-sm {
    line-height: 1.5 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: var(--bs-border-radius) !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: var(--bs-border-radius-sm) !important
}

.rounded-2 {
    border-radius: var(--bs-border-radius) !important
}

.rounded-3 {
    border-radius: var(--bs-border-radius-lg) !important
}

.rounded-4 {
    border-radius: var(--bs-border-radius-xl) !important
}

.rounded-5 {
    border-radius: var(--bs-border-radius-2xl) !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: var(--bs-border-radius-pill) !important
}

.rounded-top {
    border-top-left-radius: var(--bs-border-radius) !important
}

.rounded-end,
.rounded-top {
    border-top-right-radius: var(--bs-border-radius) !important
}

.rounded-bottom,
.rounded-end {
    border-bottom-right-radius: var(--bs-border-radius) !important
}

.rounded-bottom,
.rounded-start {
    border-bottom-left-radius: var(--bs-border-radius) !important
}

.rounded-start {
    border-top-left-radius: var(--bs-border-radius) !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media(min-width:576px) {
    .float-sm-start {
        float: left !important
    }
    .float-sm-end {
        float: right !important
    }
    .float-sm-none {
        float: none !important
    }
    .d-sm-inline {
        display: inline !important
    }
    .d-sm-inline-block {
        display: inline-block !important
    }
    .d-sm-block {
        display: block !important
    }
    .d-sm-grid {
        display: grid !important
    }
    .d-sm-table {
        display: table !important
    }
    .d-sm-table-row {
        display: table-row !important
    }
    .d-sm-table-cell {
        display: table-cell !important
    }
    .d-sm-flex {
        display: flex !important
    }
    .d-sm-inline-flex {
        display: inline-flex !important
    }
    .d-sm-none {
        display: none !important
    }
    .flex-sm-fill {
        flex: 1 1 auto !important
    }
    .flex-sm-row {
        flex-direction: row !important
    }
    .flex-sm-column {
        flex-direction: column !important
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .justify-content-sm-start {
        justify-content: flex-start !important
    }
    .justify-content-sm-end {
        justify-content: flex-end !important
    }
    .justify-content-sm-center {
        justify-content: center !important
    }
    .justify-content-sm-between {
        justify-content: space-between !important
    }
    .justify-content-sm-around {
        justify-content: space-around !important
    }
    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }
    .align-items-sm-start {
        align-items: flex-start !important
    }
    .align-items-sm-end {
        align-items: flex-end !important
    }
    .align-items-sm-center {
        align-items: center !important
    }
    .align-items-sm-baseline {
        align-items: baseline !important
    }
    .align-items-sm-stretch {
        align-items: stretch !important
    }
    .align-content-sm-start {
        align-content: flex-start !important
    }
    .align-content-sm-end {
        align-content: flex-end !important
    }
    .align-content-sm-center {
        align-content: center !important
    }
    .align-content-sm-between {
        align-content: space-between !important
    }
    .align-content-sm-around {
        align-content: space-around !important
    }
    .align-content-sm-stretch {
        align-content: stretch !important
    }
    .align-self-sm-auto {
        align-self: auto !important
    }
    .align-self-sm-start {
        align-self: flex-start !important
    }
    .align-self-sm-end {
        align-self: flex-end !important
    }
    .align-self-sm-center {
        align-self: center !important
    }
    .align-self-sm-baseline {
        align-self: baseline !important
    }
    .align-self-sm-stretch {
        align-self: stretch !important
    }
    .order-sm-first {
        order: -1 !important
    }
    .order-sm-0 {
        order: 0 !important
    }
    .order-sm-1 {
        order: 1 !important
    }
    .order-sm-2 {
        order: 2 !important
    }
    .order-sm-3 {
        order: 3 !important
    }
    .order-sm-4 {
        order: 4 !important
    }
    .order-sm-5 {
        order: 5 !important
    }
    .order-sm-last {
        order: 6 !important
    }
    .m-sm-0 {
        margin: 0 !important
    }
    .m-sm-1 {
        margin: .25rem !important
    }
    .m-sm-2 {
        margin: .5rem !important
    }
    .m-sm-3 {
        margin: 1rem !important
    }
    .m-sm-4 {
        margin: 1.5rem !important
    }
    .m-sm-5 {
        margin: 3rem !important
    }
    .m-sm-6 {
        margin: 4.5rem !important
    }
    .m-sm-7 {
        margin: 6rem !important
    }
    .m-sm-auto {
        margin: auto !important
    }
    .mx-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }
    .mx-sm-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }
    .mx-sm-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }
    .mx-sm-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }
    .mx-sm-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }
    .mx-sm-5 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }
    .mx-sm-6 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }
    .mx-sm-7 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }
    .mx-sm-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }
    .my-sm-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }
    .my-sm-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }
    .my-sm-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }
    .my-sm-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }
    .my-sm-5 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }
    .my-sm-6 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }
    .my-sm-7 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }
    .my-sm-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }
    .mt-sm-0 {
        margin-top: 0 !important
    }
    .mt-sm-1 {
        margin-top: .25rem !important
    }
    .mt-sm-2 {
        margin-top: .5rem !important
    }
    .mt-sm-3 {
        margin-top: 1rem !important
    }
    .mt-sm-4 {
        margin-top: 1.5rem !important
    }
    .mt-sm-5 {
        margin-top: 3rem !important
    }
    .mt-sm-6 {
        margin-top: 4.5rem !important
    }
    .mt-sm-7 {
        margin-top: 6rem !important
    }
    .mt-sm-auto {
        margin-top: auto !important
    }
    .me-sm-0 {
        margin-right: 0 !important
    }
    .me-sm-1 {
        margin-right: .25rem !important
    }
    .me-sm-2 {
        margin-right: .5rem !important
    }
    .me-sm-3 {
        margin-right: 1rem !important
    }
    .me-sm-4 {
        margin-right: 1.5rem !important
    }
    .me-sm-5 {
        margin-right: 3rem !important
    }
    .me-sm-6 {
        margin-right: 4.5rem !important
    }
    .me-sm-7 {
        margin-right: 6rem !important
    }
    .me-sm-auto {
        margin-right: auto !important
    }
    .mb-sm-0 {
        margin-bottom: 0 !important
    }
    .mb-sm-1 {
        margin-bottom: .25rem !important
    }
    .mb-sm-2 {
        margin-bottom: .5rem !important
    }
    .mb-sm-3 {
        margin-bottom: 1rem !important
    }
    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-sm-5 {
        margin-bottom: 3rem !important
    }
    .mb-sm-6 {
        margin-bottom: 4.5rem !important
    }
    .mb-sm-7 {
        margin-bottom: 6rem !important
    }
    .mb-sm-auto {
        margin-bottom: auto !important
    }
    .ms-sm-0 {
        margin-left: 0 !important
    }
    .ms-sm-1 {
        margin-left: .25rem !important
    }
    .ms-sm-2 {
        margin-left: .5rem !important
    }
    .ms-sm-3 {
        margin-left: 1rem !important
    }
    .ms-sm-4 {
        margin-left: 1.5rem !important
    }
    .ms-sm-5 {
        margin-left: 3rem !important
    }
    .ms-sm-6 {
        margin-left: 4.5rem !important
    }
    .ms-sm-7 {
        margin-left: 6rem !important
    }
    .ms-sm-auto {
        margin-left: auto !important
    }
    .m-sm-n1 {
        margin: -.25rem !important
    }
    .m-sm-n2 {
        margin: -.5rem !important
    }
    .m-sm-n3 {
        margin: -1rem !important
    }
    .m-sm-n4 {
        margin: -1.5rem !important
    }
    .m-sm-n5 {
        margin: -3rem !important
    }
    .m-sm-n6 {
        margin: -4.5rem !important
    }
    .m-sm-n7 {
        margin: -6rem !important
    }
    .mx-sm-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }
    .mx-sm-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }
    .mx-sm-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }
    .mx-sm-n5 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }
    .mx-sm-n6 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }
    .mx-sm-n7 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }
    .my-sm-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }
    .my-sm-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }
    .my-sm-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }
    .my-sm-n5 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }
    .my-sm-n6 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }
    .my-sm-n7 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }
    .mt-sm-n1 {
        margin-top: -.25rem !important
    }
    .mt-sm-n2 {
        margin-top: -.5rem !important
    }
    .mt-sm-n3 {
        margin-top: -1rem !important
    }
    .mt-sm-n4 {
        margin-top: -1.5rem !important
    }
    .mt-sm-n5 {
        margin-top: -3rem !important
    }
    .mt-sm-n6 {
        margin-top: -4.5rem !important
    }
    .mt-sm-n7 {
        margin-top: -6rem !important
    }
    .me-sm-n1 {
        margin-right: -.25rem !important
    }
    .me-sm-n2 {
        margin-right: -.5rem !important
    }
    .me-sm-n3 {
        margin-right: -1rem !important
    }
    .me-sm-n4 {
        margin-right: -1.5rem !important
    }
    .me-sm-n5 {
        margin-right: -3rem !important
    }
    .me-sm-n6 {
        margin-right: -4.5rem !important
    }
    .me-sm-n7 {
        margin-right: -6rem !important
    }
    .mb-sm-n1 {
        margin-bottom: -.25rem !important
    }
    .mb-sm-n2 {
        margin-bottom: -.5rem !important
    }
    .mb-sm-n3 {
        margin-bottom: -1rem !important
    }
    .mb-sm-n4 {
        margin-bottom: -1.5rem !important
    }
    .mb-sm-n5 {
        margin-bottom: -3rem !important
    }
    .mb-sm-n6 {
        margin-bottom: -4.5rem !important
    }
    .mb-sm-n7 {
        margin-bottom: -6rem !important
    }
    .ms-sm-n1 {
        margin-left: -.25rem !important
    }
    .ms-sm-n2 {
        margin-left: -.5rem !important
    }
    .ms-sm-n3 {
        margin-left: -1rem !important
    }
    .ms-sm-n4 {
        margin-left: -1.5rem !important
    }
    .ms-sm-n5 {
        margin-left: -3rem !important
    }
    .ms-sm-n6 {
        margin-left: -4.5rem !important
    }
    .ms-sm-n7 {
        margin-left: -6rem !important
    }
    .p-sm-0 {
        padding: 0 !important
    }
    .p-sm-1 {
        padding: .25rem !important
    }
    .p-sm-2 {
        padding: .5rem !important
    }
    .p-sm-3 {
        padding: 1rem !important
    }
    .p-sm-4 {
        padding: 1.5rem !important
    }
    .p-sm-5 {
        padding: 3rem !important
    }
    .p-sm-6 {
        padding: 4.5rem !important
    }
    .p-sm-7 {
        padding: 6rem !important
    }
    .px-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .px-sm-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }
    .px-sm-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }
    .px-sm-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }
    .px-sm-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }
    .px-sm-5 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }
    .px-sm-6 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }
    .px-sm-7 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }
    .py-sm-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }
    .py-sm-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }
    .py-sm-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }
    .py-sm-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }
    .py-sm-5 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }
    .py-sm-6 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }
    .py-sm-7 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }
    .pt-sm-0 {
        padding-top: 0 !important
    }
    .pt-sm-1 {
        padding-top: .25rem !important
    }
    .pt-sm-2 {
        padding-top: .5rem !important
    }
    .pt-sm-3 {
        padding-top: 1rem !important
    }
    .pt-sm-4 {
        padding-top: 1.5rem !important
    }
    .pt-sm-5 {
        padding-top: 3rem !important
    }
    .pt-sm-6 {
        padding-top: 4.5rem !important
    }
    .pt-sm-7 {
        padding-top: 6rem !important
    }
    .pe-sm-0 {
        padding-right: 0 !important
    }
    .pe-sm-1 {
        padding-right: .25rem !important
    }
    .pe-sm-2 {
        padding-right: .5rem !important
    }
    .pe-sm-3 {
        padding-right: 1rem !important
    }
    .pe-sm-4 {
        padding-right: 1.5rem !important
    }
    .pe-sm-5 {
        padding-right: 3rem !important
    }
    .pe-sm-6 {
        padding-right: 4.5rem !important
    }
    .pe-sm-7 {
        padding-right: 6rem !important
    }
    .pb-sm-0 {
        padding-bottom: 0 !important
    }
    .pb-sm-1 {
        padding-bottom: .25rem !important
    }
    .pb-sm-2 {
        padding-bottom: .5rem !important
    }
    .pb-sm-3 {
        padding-bottom: 1rem !important
    }
    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-sm-5 {
        padding-bottom: 3rem !important
    }
    .pb-sm-6 {
        padding-bottom: 4.5rem !important
    }
    .pb-sm-7 {
        padding-bottom: 6rem !important
    }
    .ps-sm-0 {
        padding-left: 0 !important
    }
    .ps-sm-1 {
        padding-left: .25rem !important
    }
    .ps-sm-2 {
        padding-left: .5rem !important
    }
    .ps-sm-3 {
        padding-left: 1rem !important
    }
    .ps-sm-4 {
        padding-left: 1.5rem !important
    }
    .ps-sm-5 {
        padding-left: 3rem !important
    }
    .ps-sm-6 {
        padding-left: 4.5rem !important
    }
    .ps-sm-7 {
        padding-left: 6rem !important
    }
    .gap-sm-0 {
        gap: 0 !important
    }
    .gap-sm-1 {
        gap: .25rem !important
    }
    .gap-sm-2 {
        gap: .5rem !important
    }
    .gap-sm-3 {
        gap: 1rem !important
    }
    .gap-sm-4 {
        gap: 1.5rem !important
    }
    .gap-sm-5 {
        gap: 3rem !important
    }
    .gap-sm-6 {
        gap: 4.5rem !important
    }
    .gap-sm-7 {
        gap: 6rem !important
    }
    .text-sm-start {
        text-align: left !important
    }
    .text-sm-end {
        text-align: right !important
    }
    .text-sm-center {
        text-align: center !important
    }
}

@media(min-width:768px) {
    .float-md-start {
        float: left !important
    }
    .float-md-end {
        float: right !important
    }
    .float-md-none {
        float: none !important
    }
    .d-md-inline {
        display: inline !important
    }
    .d-md-inline-block {
        display: inline-block !important
    }
    .d-md-block {
        display: block !important
    }
    .d-md-grid {
        display: grid !important
    }
    .d-md-table {
        display: table !important
    }
    .d-md-table-row {
        display: table-row !important
    }
    .d-md-table-cell {
        display: table-cell !important
    }
    .d-md-flex {
        display: flex !important
    }
    .d-md-inline-flex {
        display: inline-flex !important
    }
    .d-md-none {
        display: none !important
    }
    .flex-md-fill {
        flex: 1 1 auto !important
    }
    .flex-md-row {
        flex-direction: row !important
    }
    .flex-md-column {
        flex-direction: column !important
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-md-wrap {
        flex-wrap: wrap !important
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .justify-content-md-start {
        justify-content: flex-start !important
    }
    .justify-content-md-end {
        justify-content: flex-end !important
    }
    .justify-content-md-center {
        justify-content: center !important
    }
    .justify-content-md-between {
        justify-content: space-between !important
    }
    .justify-content-md-around {
        justify-content: space-around !important
    }
    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }
    .align-items-md-start {
        align-items: flex-start !important
    }
    .align-items-md-end {
        align-items: flex-end !important
    }
    .align-items-md-center {
        align-items: center !important
    }
    .align-items-md-baseline {
        align-items: baseline !important
    }
    .align-items-md-stretch {
        align-items: stretch !important
    }
    .align-content-md-start {
        align-content: flex-start !important
    }
    .align-content-md-end {
        align-content: flex-end !important
    }
    .align-content-md-center {
        align-content: center !important
    }
    .align-content-md-between {
        align-content: space-between !important
    }
    .align-content-md-around {
        align-content: space-around !important
    }
    .align-content-md-stretch {
        align-content: stretch !important
    }
    .align-self-md-auto {
        align-self: auto !important
    }
    .align-self-md-start {
        align-self: flex-start !important
    }
    .align-self-md-end {
        align-self: flex-end !important
    }
    .align-self-md-center {
        align-self: center !important
    }
    .align-self-md-baseline {
        align-self: baseline !important
    }
    .align-self-md-stretch {
        align-self: stretch !important
    }
    .order-md-first {
        order: -1 !important
    }
    .order-md-0 {
        order: 0 !important
    }
    .order-md-1 {
        order: 1 !important
    }
    .order-md-2 {
        order: 2 !important
    }
    .order-md-3 {
        order: 3 !important
    }
    .order-md-4 {
        order: 4 !important
    }
    .order-md-5 {
        order: 5 !important
    }
    .order-md-last {
        order: 6 !important
    }
    .m-md-0 {
        margin: 0 !important
    }
    .m-md-1 {
        margin: .25rem !important
    }
    .m-md-2 {
        margin: .5rem !important
    }
    .m-md-3 {
        margin: 1rem !important
    }
    .m-md-4 {
        margin: 1.5rem !important
    }
    .m-md-5 {
        margin: 3rem !important
    }
    .m-md-6 {
        margin: 4.5rem !important
    }
    .m-md-7 {
        margin: 6rem !important
    }
    .m-md-auto {
        margin: auto !important
    }
    .mx-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }
    .mx-md-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }
    .mx-md-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }
    .mx-md-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }
    .mx-md-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }
    .mx-md-5 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }
    .mx-md-6 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }
    .mx-md-7 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }
    .mx-md-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }
    .my-md-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }
    .my-md-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }
    .my-md-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }
    .my-md-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }
    .my-md-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }
    .my-md-5 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }
    .my-md-6 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }
    .my-md-7 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }
    .my-md-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }
    .mt-md-0 {
        margin-top: 0 !important
    }
    .mt-md-1 {
        margin-top: .25rem !important
    }
    .mt-md-2 {
        margin-top: .5rem !important
    }
    .mt-md-3 {
        margin-top: 1rem !important
    }
    .mt-md-4 {
        margin-top: 1.5rem !important
    }
    .mt-md-5 {
        margin-top: 3rem !important
    }
    .mt-md-6 {
        margin-top: 4.5rem !important
    }
    .mt-md-7 {
        margin-top: 6rem !important
    }
    .mt-md-auto {
        margin-top: auto !important
    }
    .me-md-0 {
        margin-right: 0 !important
    }
    .me-md-1 {
        margin-right: .25rem !important
    }
    .me-md-2 {
        margin-right: .5rem !important
    }
    .me-md-3 {
        margin-right: 1rem !important
    }
    .me-md-4 {
        margin-right: 1.5rem !important
    }
    .me-md-5 {
        margin-right: 3rem !important
    }
    .me-md-6 {
        margin-right: 4.5rem !important
    }
    .me-md-7 {
        margin-right: 6rem !important
    }
    .me-md-auto {
        margin-right: auto !important
    }
    .mb-md-0 {
        margin-bottom: 0 !important
    }
    .mb-md-1 {
        margin-bottom: .25rem !important
    }
    .mb-md-2 {
        margin-bottom: .5rem !important
    }
    .mb-md-3 {
        margin-bottom: 1rem !important
    }
    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-md-5 {
        margin-bottom: 3rem !important
    }
    .mb-md-6 {
        margin-bottom: 4.5rem !important
    }
    .mb-md-7 {
        margin-bottom: 6rem !important
    }
    .mb-md-auto {
        margin-bottom: auto !important
    }
    .ms-md-0 {
        margin-left: 0 !important
    }
    .ms-md-1 {
        margin-left: .25rem !important
    }
    .ms-md-2 {
        margin-left: .5rem !important
    }
    .ms-md-3 {
        margin-left: 1rem !important
    }
    .ms-md-4 {
        margin-left: 1.5rem !important
    }
    .ms-md-5 {
        margin-left: 3rem !important
    }
    .ms-md-6 {
        margin-left: 4.5rem !important
    }
    .ms-md-7 {
        margin-left: 6rem !important
    }
    .ms-md-auto {
        margin-left: auto !important
    }
    .m-md-n1 {
        margin: -.25rem !important
    }
    .m-md-n2 {
        margin: -.5rem !important
    }
    .m-md-n3 {
        margin: -1rem !important
    }
    .m-md-n4 {
        margin: -1.5rem !important
    }
    .m-md-n5 {
        margin: -3rem !important
    }
    .m-md-n6 {
        margin: -4.5rem !important
    }
    .m-md-n7 {
        margin: -6rem !important
    }
    .mx-md-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }
    .mx-md-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }
    .mx-md-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }
    .mx-md-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }
    .mx-md-n5 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }
    .mx-md-n6 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }
    .mx-md-n7 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }
    .my-md-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }
    .my-md-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }
    .my-md-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }
    .my-md-n5 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }
    .my-md-n6 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }
    .my-md-n7 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }
    .mt-md-n1 {
        margin-top: -.25rem !important
    }
    .mt-md-n2 {
        margin-top: -.5rem !important
    }
    .mt-md-n3 {
        margin-top: -1rem !important
    }
    .mt-md-n4 {
        margin-top: -1.5rem !important
    }
    .mt-md-n5 {
        margin-top: -3rem !important
    }
    .mt-md-n6 {
        margin-top: -4.5rem !important
    }
    .mt-md-n7 {
        margin-top: -6rem !important
    }
    .me-md-n1 {
        margin-right: -.25rem !important
    }
    .me-md-n2 {
        margin-right: -.5rem !important
    }
    .me-md-n3 {
        margin-right: -1rem !important
    }
    .me-md-n4 {
        margin-right: -1.5rem !important
    }
    .me-md-n5 {
        margin-right: -3rem !important
    }
    .me-md-n6 {
        margin-right: -4.5rem !important
    }
    .me-md-n7 {
        margin-right: -6rem !important
    }
    .mb-md-n1 {
        margin-bottom: -.25rem !important
    }
    .mb-md-n2 {
        margin-bottom: -.5rem !important
    }
    .mb-md-n3 {
        margin-bottom: -1rem !important
    }
    .mb-md-n4 {
        margin-bottom: -1.5rem !important
    }
    .mb-md-n5 {
        margin-bottom: -3rem !important
    }
    .mb-md-n6 {
        margin-bottom: -4.5rem !important
    }
    .mb-md-n7 {
        margin-bottom: -6rem !important
    }
    .ms-md-n1 {
        margin-left: -.25rem !important
    }
    .ms-md-n2 {
        margin-left: -.5rem !important
    }
    .ms-md-n3 {
        margin-left: -1rem !important
    }
    .ms-md-n4 {
        margin-left: -1.5rem !important
    }
    .ms-md-n5 {
        margin-left: -3rem !important
    }
    .ms-md-n6 {
        margin-left: -4.5rem !important
    }
    .ms-md-n7 {
        margin-left: -6rem !important
    }
    .p-md-0 {
        padding: 0 !important
    }
    .p-md-1 {
        padding: .25rem !important
    }
    .p-md-2 {
        padding: .5rem !important
    }
    .p-md-3 {
        padding: 1rem !important
    }
    .p-md-4 {
        padding: 1.5rem !important
    }
    .p-md-5 {
        padding: 3rem !important
    }
    .p-md-6 {
        padding: 4.5rem !important
    }
    .p-md-7 {
        padding: 6rem !important
    }
    .px-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .px-md-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }
    .px-md-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }
    .px-md-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }
    .px-md-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }
    .px-md-5 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }
    .px-md-6 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }
    .px-md-7 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }
    .py-md-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }
    .py-md-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }
    .py-md-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }
    .py-md-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }
    .py-md-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }
    .py-md-5 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }
    .py-md-6 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }
    .py-md-7 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }
    .pt-md-0 {
        padding-top: 0 !important
    }
    .pt-md-1 {
        padding-top: .25rem !important
    }
    .pt-md-2 {
        padding-top: .5rem !important
    }
    .pt-md-3 {
        padding-top: 1rem !important
    }
    .pt-md-4 {
        padding-top: 1.5rem !important
    }
    .pt-md-5 {
        padding-top: 3rem !important
    }
    .pt-md-6 {
        padding-top: 4.5rem !important
    }
    .pt-md-7 {
        padding-top: 6rem !important
    }
    .pe-md-0 {
        padding-right: 0 !important
    }
    .pe-md-1 {
        padding-right: .25rem !important
    }
    .pe-md-2 {
        padding-right: .5rem !important
    }
    .pe-md-3 {
        padding-right: 1rem !important
    }
    .pe-md-4 {
        padding-right: 1.5rem !important
    }
    .pe-md-5 {
        padding-right: 3rem !important
    }
    .pe-md-6 {
        padding-right: 4.5rem !important
    }
    .pe-md-7 {
        padding-right: 6rem !important
    }
    .pb-md-0 {
        padding-bottom: 0 !important
    }
    .pb-md-1 {
        padding-bottom: .25rem !important
    }
    .pb-md-2 {
        padding-bottom: .5rem !important
    }
    .pb-md-3 {
        padding-bottom: 1rem !important
    }
    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-md-5 {
        padding-bottom: 3rem !important
    }
    .pb-md-6 {
        padding-bottom: 4.5rem !important
    }
    .pb-md-7 {
        padding-bottom: 6rem !important
    }
    .ps-md-0 {
        padding-left: 0 !important
    }
    .ps-md-1 {
        padding-left: .25rem !important
    }
    .ps-md-2 {
        padding-left: .5rem !important
    }
    .ps-md-3 {
        padding-left: 1rem !important
    }
    .ps-md-4 {
        padding-left: 1.5rem !important
    }
    .ps-md-5 {
        padding-left: 3rem !important
    }
    .ps-md-6 {
        padding-left: 4.5rem !important
    }
    .ps-md-7 {
        padding-left: 6rem !important
    }
    .gap-md-0 {
        gap: 0 !important
    }
    .gap-md-1 {
        gap: .25rem !important
    }
    .gap-md-2 {
        gap: .5rem !important
    }
    .gap-md-3 {
        gap: 1rem !important
    }
    .gap-md-4 {
        gap: 1.5rem !important
    }
    .gap-md-5 {
        gap: 3rem !important
    }
    .gap-md-6 {
        gap: 4.5rem !important
    }
    .gap-md-7 {
        gap: 6rem !important
    }
    .text-md-start {
        text-align: left !important
    }
    .text-md-end {
        text-align: right !important
    }
    .text-md-center {
        text-align: center !important
    }
}

@media(min-width:992px) {
    .float-lg-start {
        float: left !important
    }
    .float-lg-end {
        float: right !important
    }
    .float-lg-none {
        float: none !important
    }
    .d-lg-inline {
        display: inline !important
    }
    .d-lg-inline-block {
        display: inline-block !important
    }
    .d-lg-block {
        display: block !important
    }
    .d-lg-grid {
        display: grid !important
    }
    .d-lg-table {
        display: table !important
    }
    .d-lg-table-row {
        display: table-row !important
    }
    .d-lg-table-cell {
        display: table-cell !important
    }
    .d-lg-flex {
        display: flex !important
    }
    .d-lg-inline-flex {
        display: inline-flex !important
    }
    .d-lg-none {
        display: none !important
    }
    .flex-lg-fill {
        flex: 1 1 auto !important
    }
    .flex-lg-row {
        flex-direction: row !important
    }
    .flex-lg-column {
        flex-direction: column !important
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .justify-content-lg-start {
        justify-content: flex-start !important
    }
    .justify-content-lg-end {
        justify-content: flex-end !important
    }
    .justify-content-lg-center {
        justify-content: center !important
    }
    .justify-content-lg-between {
        justify-content: space-between !important
    }
    .justify-content-lg-around {
        justify-content: space-around !important
    }
    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }
    .align-items-lg-start {
        align-items: flex-start !important
    }
    .align-items-lg-end {
        align-items: flex-end !important
    }
    .align-items-lg-center {
        align-items: center !important
    }
    .align-items-lg-baseline {
        align-items: baseline !important
    }
    .align-items-lg-stretch {
        align-items: stretch !important
    }
    .align-content-lg-start {
        align-content: flex-start !important
    }
    .align-content-lg-end {
        align-content: flex-end !important
    }
    .align-content-lg-center {
        align-content: center !important
    }
    .align-content-lg-between {
        align-content: space-between !important
    }
    .align-content-lg-around {
        align-content: space-around !important
    }
    .align-content-lg-stretch {
        align-content: stretch !important
    }
    .align-self-lg-auto {
        align-self: auto !important
    }
    .align-self-lg-start {
        align-self: flex-start !important
    }
    .align-self-lg-end {
        align-self: flex-end !important
    }
    .align-self-lg-center {
        align-self: center !important
    }
    .align-self-lg-baseline {
        align-self: baseline !important
    }
    .align-self-lg-stretch {
        align-self: stretch !important
    }
    .order-lg-first {
        order: -1 !important
    }
    .order-lg-0 {
        order: 0 !important
    }
    .order-lg-1 {
        order: 1 !important
    }
    .order-lg-2 {
        order: 2 !important
    }
    .order-lg-3 {
        order: 3 !important
    }
    .order-lg-4 {
        order: 4 !important
    }
    .order-lg-5 {
        order: 5 !important
    }
    .order-lg-last {
        order: 6 !important
    }
    .m-lg-0 {
        margin: 0 !important
    }
    .m-lg-1 {
        margin: .25rem !important
    }
    .m-lg-2 {
        margin: .5rem !important
    }
    .m-lg-3 {
        margin: 1rem !important
    }
    .m-lg-4 {
        margin: 1.5rem !important
    }
    .m-lg-5 {
        margin: 3rem !important
    }
    .m-lg-6 {
        margin: 4.5rem !important
    }
    .m-lg-7 {
        margin: 6rem !important
    }
    .m-lg-auto {
        margin: auto !important
    }
    .mx-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }
    .mx-lg-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }
    .mx-lg-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }
    .mx-lg-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }
    .mx-lg-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }
    .mx-lg-5 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }
    .mx-lg-6 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }
    .mx-lg-7 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }
    .mx-lg-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }
    .my-lg-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }
    .my-lg-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }
    .my-lg-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }
    .my-lg-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }
    .my-lg-5 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }
    .my-lg-6 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }
    .my-lg-7 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }
    .my-lg-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }
    .mt-lg-0 {
        margin-top: 0 !important
    }
    .mt-lg-1 {
        margin-top: .25rem !important
    }
    .mt-lg-2 {
        margin-top: .5rem !important
    }
    .mt-lg-3 {
        margin-top: 1rem !important
    }
    .mt-lg-4 {
        margin-top: 1.5rem !important
    }
    .mt-lg-5 {
        margin-top: 3rem !important
    }
    .mt-lg-6 {
        margin-top: 4.5rem !important
    }
    .mt-lg-7 {
        margin-top: 6rem !important
    }
    .mt-lg-auto {
        margin-top: auto !important
    }
    .me-lg-0 {
        margin-right: 0 !important
    }
    .me-lg-1 {
        margin-right: .25rem !important
    }
    .me-lg-2 {
        margin-right: .5rem !important
    }
    .me-lg-3 {
        margin-right: 1rem !important
    }
    .me-lg-4 {
        margin-right: 1.5rem !important
    }
    .me-lg-5 {
        margin-right: 3rem !important
    }
    .me-lg-6 {
        margin-right: 4.5rem !important
    }
    .me-lg-7 {
        margin-right: 6rem !important
    }
    .me-lg-auto {
        margin-right: auto !important
    }
    .mb-lg-0 {
        margin-bottom: 0 !important
    }
    .mb-lg-1 {
        margin-bottom: .25rem !important
    }
    .mb-lg-2 {
        margin-bottom: .5rem !important
    }
    .mb-lg-3 {
        margin-bottom: 1rem !important
    }
    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-lg-5 {
        margin-bottom: 3rem !important
    }
    .mb-lg-6 {
        margin-bottom: 4.5rem !important
    }
    .mb-lg-7 {
        margin-bottom: 6rem !important
    }
    .mb-lg-auto {
        margin-bottom: auto !important
    }
    .ms-lg-0 {
        margin-left: 0 !important
    }
    .ms-lg-1 {
        margin-left: .25rem !important
    }
    .ms-lg-2 {
        margin-left: .5rem !important
    }
    .ms-lg-3 {
        margin-left: 1rem !important
    }
    .ms-lg-4 {
        margin-left: 1.5rem !important
    }
    .ms-lg-5 {
        margin-left: 3rem !important
    }
    .ms-lg-6 {
        margin-left: 4.5rem !important
    }
    .ms-lg-7 {
        margin-left: 6rem !important
    }
    .ms-lg-auto {
        margin-left: auto !important
    }
    .m-lg-n1 {
        margin: -.25rem !important
    }
    .m-lg-n2 {
        margin: -.5rem !important
    }
    .m-lg-n3 {
        margin: -1rem !important
    }
    .m-lg-n4 {
        margin: -1.5rem !important
    }
    .m-lg-n5 {
        margin: -3rem !important
    }
    .m-lg-n6 {
        margin: -4.5rem !important
    }
    .m-lg-n7 {
        margin: -6rem !important
    }
    .mx-lg-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }
    .mx-lg-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }
    .mx-lg-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }
    .mx-lg-n5 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }
    .mx-lg-n6 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }
    .mx-lg-n7 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }
    .my-lg-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }
    .my-lg-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }
    .my-lg-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }
    .my-lg-n5 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }
    .my-lg-n6 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }
    .my-lg-n7 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }
    .mt-lg-n1 {
        margin-top: -.25rem !important
    }
    .mt-lg-n2 {
        margin-top: -.5rem !important
    }
    .mt-lg-n3 {
        margin-top: -1rem !important
    }
    .mt-lg-n4 {
        margin-top: -1.5rem !important
    }
    .mt-lg-n5 {
        margin-top: -3rem !important
    }
    .mt-lg-n6 {
        margin-top: -4.5rem !important
    }
    .mt-lg-n7 {
        margin-top: -6rem !important
    }
    .me-lg-n1 {
        margin-right: -.25rem !important
    }
    .me-lg-n2 {
        margin-right: -.5rem !important
    }
    .me-lg-n3 {
        margin-right: -1rem !important
    }
    .me-lg-n4 {
        margin-right: -1.5rem !important
    }
    .me-lg-n5 {
        margin-right: -3rem !important
    }
    .me-lg-n6 {
        margin-right: -4.5rem !important
    }
    .me-lg-n7 {
        margin-right: -6rem !important
    }
    .mb-lg-n1 {
        margin-bottom: -.25rem !important
    }
    .mb-lg-n2 {
        margin-bottom: -.5rem !important
    }
    .mb-lg-n3 {
        margin-bottom: -1rem !important
    }
    .mb-lg-n4 {
        margin-bottom: -1.5rem !important
    }
    .mb-lg-n5 {
        margin-bottom: -3rem !important
    }
    .mb-lg-n6 {
        margin-bottom: -4.5rem !important
    }
    .mb-lg-n7 {
        margin-bottom: -6rem !important
    }
    .ms-lg-n1 {
        margin-left: -.25rem !important
    }
    .ms-lg-n2 {
        margin-left: -.5rem !important
    }
    .ms-lg-n3 {
        margin-left: -1rem !important
    }
    .ms-lg-n4 {
        margin-left: -1.5rem !important
    }
    .ms-lg-n5 {
        margin-left: -3rem !important
    }
    .ms-lg-n6 {
        margin-left: -4.5rem !important
    }
    .ms-lg-n7 {
        margin-left: -6rem !important
    }
    .p-lg-0 {
        padding: 0 !important
    }
    .p-lg-1 {
        padding: .25rem !important
    }
    .p-lg-2 {
        padding: .5rem !important
    }
    .p-lg-3 {
        padding: 1rem !important
    }
    .p-lg-4 {
        padding: 1.5rem !important
    }
    .p-lg-5 {
        padding: 3rem !important
    }
    .p-lg-6 {
        padding: 4.5rem !important
    }
    .p-lg-7 {
        padding: 6rem !important
    }
    .px-lg-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .px-lg-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }
    .px-lg-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }
    .px-lg-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }
    .px-lg-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }
    .px-lg-5 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }
    .px-lg-6 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }
    .px-lg-7 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }
    .py-lg-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }
    .py-lg-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }
    .py-lg-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }
    .py-lg-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }
    .py-lg-5 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }
    .py-lg-6 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }
    .py-lg-7 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }
    .pt-lg-0 {
        padding-top: 0 !important
    }
    .pt-lg-1 {
        padding-top: .25rem !important
    }
    .pt-lg-2 {
        padding-top: .5rem !important
    }
    .pt-lg-3 {
        padding-top: 1rem !important
    }
    .pt-lg-4 {
        padding-top: 1.5rem !important
    }
    .pt-lg-5 {
        padding-top: 3rem !important
    }
    .pt-lg-6 {
        padding-top: 4.5rem !important
    }
    .pt-lg-7 {
        padding-top: 6rem !important
    }
    .pe-lg-0 {
        padding-right: 0 !important
    }
    .pe-lg-1 {
        padding-right: .25rem !important
    }
    .pe-lg-2 {
        padding-right: .5rem !important
    }
    .pe-lg-3 {
        padding-right: 1rem !important
    }
    .pe-lg-4 {
        padding-right: 1.5rem !important
    }
    .pe-lg-5 {
        padding-right: 3rem !important
    }
    .pe-lg-6 {
        padding-right: 4.5rem !important
    }
    .pe-lg-7 {
        padding-right: 6rem !important
    }
    .pb-lg-0 {
        padding-bottom: 0 !important
    }
    .pb-lg-1 {
        padding-bottom: .25rem !important
    }
    .pb-lg-2 {
        padding-bottom: .5rem !important
    }
    .pb-lg-3 {
        padding-bottom: 1rem !important
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-lg-5 {
        padding-bottom: 3rem !important
    }
    .pb-lg-6 {
        padding-bottom: 4.5rem !important
    }
    .pb-lg-7 {
        padding-bottom: 6rem !important
    }
    .ps-lg-0 {
        padding-left: 0 !important
    }
    .ps-lg-1 {
        padding-left: .25rem !important
    }
    .ps-lg-2 {
        padding-left: .5rem !important
    }
    .ps-lg-3 {
        padding-left: 1rem !important
    }
    .ps-lg-4 {
        padding-left: 1.5rem !important
    }
    .ps-lg-5 {
        padding-left: 3rem !important
    }
    .ps-lg-6 {
        padding-left: 4.5rem !important
    }
    .ps-lg-7 {
        padding-left: 6rem !important
    }
    .gap-lg-0 {
        gap: 0 !important
    }
    .gap-lg-1 {
        gap: .25rem !important
    }
    .gap-lg-2 {
        gap: .5rem !important
    }
    .gap-lg-3 {
        gap: 1rem !important
    }
    .gap-lg-4 {
        gap: 1.5rem !important
    }
    .gap-lg-5 {
        gap: 3rem !important
    }
    .gap-lg-6 {
        gap: 4.5rem !important
    }
    .gap-lg-7 {
        gap: 6rem !important
    }
    .text-lg-start {
        text-align: left !important
    }
    .text-lg-end {
        text-align: right !important
    }
    .text-lg-center {
        text-align: center !important
    }
}

@media(min-width:1200px) {
    .float-xl-start {
        float: left !important
    }
    .float-xl-end {
        float: right !important
    }
    .float-xl-none {
        float: none !important
    }
    .d-xl-inline {
        display: inline !important
    }
    .d-xl-inline-block {
        display: inline-block !important
    }
    .d-xl-block {
        display: block !important
    }
    .d-xl-grid {
        display: grid !important
    }
    .d-xl-table {
        display: table !important
    }
    .d-xl-table-row {
        display: table-row !important
    }
    .d-xl-table-cell {
        display: table-cell !important
    }
    .d-xl-flex {
        display: flex !important
    }
    .d-xl-inline-flex {
        display: inline-flex !important
    }
    .d-xl-none {
        display: none !important
    }
    .flex-xl-fill {
        flex: 1 1 auto !important
    }
    .flex-xl-row {
        flex-direction: row !important
    }
    .flex-xl-column {
        flex-direction: column !important
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .justify-content-xl-start {
        justify-content: flex-start !important
    }
    .justify-content-xl-end {
        justify-content: flex-end !important
    }
    .justify-content-xl-center {
        justify-content: center !important
    }
    .justify-content-xl-between {
        justify-content: space-between !important
    }
    .justify-content-xl-around {
        justify-content: space-around !important
    }
    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }
    .align-items-xl-start {
        align-items: flex-start !important
    }
    .align-items-xl-end {
        align-items: flex-end !important
    }
    .align-items-xl-center {
        align-items: center !important
    }
    .align-items-xl-baseline {
        align-items: baseline !important
    }
    .align-items-xl-stretch {
        align-items: stretch !important
    }
    .align-content-xl-start {
        align-content: flex-start !important
    }
    .align-content-xl-end {
        align-content: flex-end !important
    }
    .align-content-xl-center {
        align-content: center !important
    }
    .align-content-xl-between {
        align-content: space-between !important
    }
    .align-content-xl-around {
        align-content: space-around !important
    }
    .align-content-xl-stretch {
        align-content: stretch !important
    }
    .align-self-xl-auto {
        align-self: auto !important
    }
    .align-self-xl-start {
        align-self: flex-start !important
    }
    .align-self-xl-end {
        align-self: flex-end !important
    }
    .align-self-xl-center {
        align-self: center !important
    }
    .align-self-xl-baseline {
        align-self: baseline !important
    }
    .align-self-xl-stretch {
        align-self: stretch !important
    }
    .order-xl-first {
        order: -1 !important
    }
    .order-xl-0 {
        order: 0 !important
    }
    .order-xl-1 {
        order: 1 !important
    }
    .order-xl-2 {
        order: 2 !important
    }
    .order-xl-3 {
        order: 3 !important
    }
    .order-xl-4 {
        order: 4 !important
    }
    .order-xl-5 {
        order: 5 !important
    }
    .order-xl-last {
        order: 6 !important
    }
    .m-xl-0 {
        margin: 0 !important
    }
    .m-xl-1 {
        margin: .25rem !important
    }
    .m-xl-2 {
        margin: .5rem !important
    }
    .m-xl-3 {
        margin: 1rem !important
    }
    .m-xl-4 {
        margin: 1.5rem !important
    }
    .m-xl-5 {
        margin: 3rem !important
    }
    .m-xl-6 {
        margin: 4.5rem !important
    }
    .m-xl-7 {
        margin: 6rem !important
    }
    .m-xl-auto {
        margin: auto !important
    }
    .mx-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }
    .mx-xl-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }
    .mx-xl-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }
    .mx-xl-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }
    .mx-xl-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }
    .mx-xl-5 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }
    .mx-xl-6 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }
    .mx-xl-7 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }
    .mx-xl-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }
    .my-xl-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }
    .my-xl-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }
    .my-xl-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }
    .my-xl-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }
    .my-xl-5 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }
    .my-xl-6 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }
    .my-xl-7 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }
    .my-xl-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }
    .mt-xl-0 {
        margin-top: 0 !important
    }
    .mt-xl-1 {
        margin-top: .25rem !important
    }
    .mt-xl-2 {
        margin-top: .5rem !important
    }
    .mt-xl-3 {
        margin-top: 1rem !important
    }
    .mt-xl-4 {
        margin-top: 1.5rem !important
    }
    .mt-xl-5 {
        margin-top: 3rem !important
    }
    .mt-xl-6 {
        margin-top: 4.5rem !important
    }
    .mt-xl-7 {
        margin-top: 6rem !important
    }
    .mt-xl-auto {
        margin-top: auto !important
    }
    .me-xl-0 {
        margin-right: 0 !important
    }
    .me-xl-1 {
        margin-right: .25rem !important
    }
    .me-xl-2 {
        margin-right: .5rem !important
    }
    .me-xl-3 {
        margin-right: 1rem !important
    }
    .me-xl-4 {
        margin-right: 1.5rem !important
    }
    .me-xl-5 {
        margin-right: 3rem !important
    }
    .me-xl-6 {
        margin-right: 4.5rem !important
    }
    .me-xl-7 {
        margin-right: 6rem !important
    }
    .me-xl-auto {
        margin-right: auto !important
    }
    .mb-xl-0 {
        margin-bottom: 0 !important
    }
    .mb-xl-1 {
        margin-bottom: .25rem !important
    }
    .mb-xl-2 {
        margin-bottom: .5rem !important
    }
    .mb-xl-3 {
        margin-bottom: 1rem !important
    }
    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-xl-5 {
        margin-bottom: 3rem !important
    }
    .mb-xl-6 {
        margin-bottom: 4.5rem !important
    }
    .mb-xl-7 {
        margin-bottom: 6rem !important
    }
    .mb-xl-auto {
        margin-bottom: auto !important
    }
    .ms-xl-0 {
        margin-left: 0 !important
    }
    .ms-xl-1 {
        margin-left: .25rem !important
    }
    .ms-xl-2 {
        margin-left: .5rem !important
    }
    .ms-xl-3 {
        margin-left: 1rem !important
    }
    .ms-xl-4 {
        margin-left: 1.5rem !important
    }
    .ms-xl-5 {
        margin-left: 3rem !important
    }
    .ms-xl-6 {
        margin-left: 4.5rem !important
    }
    .ms-xl-7 {
        margin-left: 6rem !important
    }
    .ms-xl-auto {
        margin-left: auto !important
    }
    .m-xl-n1 {
        margin: -.25rem !important
    }
    .m-xl-n2 {
        margin: -.5rem !important
    }
    .m-xl-n3 {
        margin: -1rem !important
    }
    .m-xl-n4 {
        margin: -1.5rem !important
    }
    .m-xl-n5 {
        margin: -3rem !important
    }
    .m-xl-n6 {
        margin: -4.5rem !important
    }
    .m-xl-n7 {
        margin: -6rem !important
    }
    .mx-xl-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }
    .mx-xl-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }
    .mx-xl-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }
    .mx-xl-n5 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }
    .mx-xl-n6 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }
    .mx-xl-n7 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }
    .my-xl-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }
    .my-xl-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }
    .my-xl-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }
    .my-xl-n5 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }
    .my-xl-n6 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }
    .my-xl-n7 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }
    .mt-xl-n1 {
        margin-top: -.25rem !important
    }
    .mt-xl-n2 {
        margin-top: -.5rem !important
    }
    .mt-xl-n3 {
        margin-top: -1rem !important
    }
    .mt-xl-n4 {
        margin-top: -1.5rem !important
    }
    .mt-xl-n5 {
        margin-top: -3rem !important
    }
    .mt-xl-n6 {
        margin-top: -4.5rem !important
    }
    .mt-xl-n7 {
        margin-top: -6rem !important
    }
    .me-xl-n1 {
        margin-right: -.25rem !important
    }
    .me-xl-n2 {
        margin-right: -.5rem !important
    }
    .me-xl-n3 {
        margin-right: -1rem !important
    }
    .me-xl-n4 {
        margin-right: -1.5rem !important
    }
    .me-xl-n5 {
        margin-right: -3rem !important
    }
    .me-xl-n6 {
        margin-right: -4.5rem !important
    }
    .me-xl-n7 {
        margin-right: -6rem !important
    }
    .mb-xl-n1 {
        margin-bottom: -.25rem !important
    }
    .mb-xl-n2 {
        margin-bottom: -.5rem !important
    }
    .mb-xl-n3 {
        margin-bottom: -1rem !important
    }
    .mb-xl-n4 {
        margin-bottom: -1.5rem !important
    }
    .mb-xl-n5 {
        margin-bottom: -3rem !important
    }
    .mb-xl-n6 {
        margin-bottom: -4.5rem !important
    }
    .mb-xl-n7 {
        margin-bottom: -6rem !important
    }
    .ms-xl-n1 {
        margin-left: -.25rem !important
    }
    .ms-xl-n2 {
        margin-left: -.5rem !important
    }
    .ms-xl-n3 {
        margin-left: -1rem !important
    }
    .ms-xl-n4 {
        margin-left: -1.5rem !important
    }
    .ms-xl-n5 {
        margin-left: -3rem !important
    }
    .ms-xl-n6 {
        margin-left: -4.5rem !important
    }
    .ms-xl-n7 {
        margin-left: -6rem !important
    }
    .p-xl-0 {
        padding: 0 !important
    }
    .p-xl-1 {
        padding: .25rem !important
    }
    .p-xl-2 {
        padding: .5rem !important
    }
    .p-xl-3 {
        padding: 1rem !important
    }
    .p-xl-4 {
        padding: 1.5rem !important
    }
    .p-xl-5 {
        padding: 3rem !important
    }
    .p-xl-6 {
        padding: 4.5rem !important
    }
    .p-xl-7 {
        padding: 6rem !important
    }
    .px-xl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .px-xl-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }
    .px-xl-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }
    .px-xl-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }
    .px-xl-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }
    .px-xl-5 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }
    .px-xl-6 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }
    .px-xl-7 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }
    .py-xl-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }
    .py-xl-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }
    .py-xl-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }
    .py-xl-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }
    .py-xl-5 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }
    .py-xl-6 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }
    .py-xl-7 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }
    .pt-xl-0 {
        padding-top: 0 !important
    }
    .pt-xl-1 {
        padding-top: .25rem !important
    }
    .pt-xl-2 {
        padding-top: .5rem !important
    }
    .pt-xl-3 {
        padding-top: 1rem !important
    }
    .pt-xl-4 {
        padding-top: 1.5rem !important
    }
    .pt-xl-5 {
        padding-top: 3rem !important
    }
    .pt-xl-6 {
        padding-top: 4.5rem !important
    }
    .pt-xl-7 {
        padding-top: 6rem !important
    }
    .pe-xl-0 {
        padding-right: 0 !important
    }
    .pe-xl-1 {
        padding-right: .25rem !important
    }
    .pe-xl-2 {
        padding-right: .5rem !important
    }
    .pe-xl-3 {
        padding-right: 1rem !important
    }
    .pe-xl-4 {
        padding-right: 1.5rem !important
    }
    .pe-xl-5 {
        padding-right: 3rem !important
    }
    .pe-xl-6 {
        padding-right: 4.5rem !important
    }
    .pe-xl-7 {
        padding-right: 6rem !important
    }
    .pb-xl-0 {
        padding-bottom: 0 !important
    }
    .pb-xl-1 {
        padding-bottom: .25rem !important
    }
    .pb-xl-2 {
        padding-bottom: .5rem !important
    }
    .pb-xl-3 {
        padding-bottom: 1rem !important
    }
    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-xl-5 {
        padding-bottom: 3rem !important
    }
    .pb-xl-6 {
        padding-bottom: 4.5rem !important
    }
    .pb-xl-7 {
        padding-bottom: 6rem !important
    }
    .ps-xl-0 {
        padding-left: 0 !important
    }
    .ps-xl-1 {
        padding-left: .25rem !important
    }
    .ps-xl-2 {
        padding-left: .5rem !important
    }
    .ps-xl-3 {
        padding-left: 1rem !important
    }
    .ps-xl-4 {
        padding-left: 1.5rem !important
    }
    .ps-xl-5 {
        padding-left: 3rem !important
    }
    .ps-xl-6 {
        padding-left: 4.5rem !important
    }
    .ps-xl-7 {
        padding-left: 6rem !important
    }
    .gap-xl-0 {
        gap: 0 !important
    }
    .gap-xl-1 {
        gap: .25rem !important
    }
    .gap-xl-2 {
        gap: .5rem !important
    }
    .gap-xl-3 {
        gap: 1rem !important
    }
    .gap-xl-4 {
        gap: 1.5rem !important
    }
    .gap-xl-5 {
        gap: 3rem !important
    }
    .gap-xl-6 {
        gap: 4.5rem !important
    }
    .gap-xl-7 {
        gap: 6rem !important
    }
    .text-xl-start {
        text-align: left !important
    }
    .text-xl-end {
        text-align: right !important
    }
    .text-xl-center {
        text-align: center !important
    }
}

@media(min-width:1440px) {
    .float-xxl-start {
        float: left !important
    }
    .float-xxl-end {
        float: right !important
    }
    .float-xxl-none {
        float: none !important
    }
    .d-xxl-inline {
        display: inline !important
    }
    .d-xxl-inline-block {
        display: inline-block !important
    }
    .d-xxl-block {
        display: block !important
    }
    .d-xxl-grid {
        display: grid !important
    }
    .d-xxl-table {
        display: table !important
    }
    .d-xxl-table-row {
        display: table-row !important
    }
    .d-xxl-table-cell {
        display: table-cell !important
    }
    .d-xxl-flex {
        display: flex !important
    }
    .d-xxl-inline-flex {
        display: inline-flex !important
    }
    .d-xxl-none {
        display: none !important
    }
    .flex-xxl-fill {
        flex: 1 1 auto !important
    }
    .flex-xxl-row {
        flex-direction: row !important
    }
    .flex-xxl-column {
        flex-direction: column !important
    }
    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }
    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }
    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }
    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .justify-content-xxl-start {
        justify-content: flex-start !important
    }
    .justify-content-xxl-end {
        justify-content: flex-end !important
    }
    .justify-content-xxl-center {
        justify-content: center !important
    }
    .justify-content-xxl-between {
        justify-content: space-between !important
    }
    .justify-content-xxl-around {
        justify-content: space-around !important
    }
    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }
    .align-items-xxl-start {
        align-items: flex-start !important
    }
    .align-items-xxl-end {
        align-items: flex-end !important
    }
    .align-items-xxl-center {
        align-items: center !important
    }
    .align-items-xxl-baseline {
        align-items: baseline !important
    }
    .align-items-xxl-stretch {
        align-items: stretch !important
    }
    .align-content-xxl-start {
        align-content: flex-start !important
    }
    .align-content-xxl-end {
        align-content: flex-end !important
    }
    .align-content-xxl-center {
        align-content: center !important
    }
    .align-content-xxl-between {
        align-content: space-between !important
    }
    .align-content-xxl-around {
        align-content: space-around !important
    }
    .align-content-xxl-stretch {
        align-content: stretch !important
    }
    .align-self-xxl-auto {
        align-self: auto !important
    }
    .align-self-xxl-start {
        align-self: flex-start !important
    }
    .align-self-xxl-end {
        align-self: flex-end !important
    }
    .align-self-xxl-center {
        align-self: center !important
    }
    .align-self-xxl-baseline {
        align-self: baseline !important
    }
    .align-self-xxl-stretch {
        align-self: stretch !important
    }
    .order-xxl-first {
        order: -1 !important
    }
    .order-xxl-0 {
        order: 0 !important
    }
    .order-xxl-1 {
        order: 1 !important
    }
    .order-xxl-2 {
        order: 2 !important
    }
    .order-xxl-3 {
        order: 3 !important
    }
    .order-xxl-4 {
        order: 4 !important
    }
    .order-xxl-5 {
        order: 5 !important
    }
    .order-xxl-last {
        order: 6 !important
    }
    .m-xxl-0 {
        margin: 0 !important
    }
    .m-xxl-1 {
        margin: .25rem !important
    }
    .m-xxl-2 {
        margin: .5rem !important
    }
    .m-xxl-3 {
        margin: 1rem !important
    }
    .m-xxl-4 {
        margin: 1.5rem !important
    }
    .m-xxl-5 {
        margin: 3rem !important
    }
    .m-xxl-6 {
        margin: 4.5rem !important
    }
    .m-xxl-7 {
        margin: 6rem !important
    }
    .m-xxl-auto {
        margin: auto !important
    }
    .mx-xxl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }
    .mx-xxl-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }
    .mx-xxl-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }
    .mx-xxl-3 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }
    .mx-xxl-4 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }
    .mx-xxl-5 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }
    .mx-xxl-6 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }
    .mx-xxl-7 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }
    .mx-xxl-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }
    .my-xxl-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }
    .my-xxl-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }
    .my-xxl-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }
    .my-xxl-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }
    .my-xxl-4 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }
    .my-xxl-5 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }
    .my-xxl-6 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }
    .my-xxl-7 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }
    .my-xxl-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }
    .mt-xxl-0 {
        margin-top: 0 !important
    }
    .mt-xxl-1 {
        margin-top: .25rem !important
    }
    .mt-xxl-2 {
        margin-top: .5rem !important
    }
    .mt-xxl-3 {
        margin-top: 1rem !important
    }
    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }
    .mt-xxl-5 {
        margin-top: 3rem !important
    }
    .mt-xxl-6 {
        margin-top: 4.5rem !important
    }
    .mt-xxl-7 {
        margin-top: 6rem !important
    }
    .mt-xxl-auto {
        margin-top: auto !important
    }
    .me-xxl-0 {
        margin-right: 0 !important
    }
    .me-xxl-1 {
        margin-right: .25rem !important
    }
    .me-xxl-2 {
        margin-right: .5rem !important
    }
    .me-xxl-3 {
        margin-right: 1rem !important
    }
    .me-xxl-4 {
        margin-right: 1.5rem !important
    }
    .me-xxl-5 {
        margin-right: 3rem !important
    }
    .me-xxl-6 {
        margin-right: 4.5rem !important
    }
    .me-xxl-7 {
        margin-right: 6rem !important
    }
    .me-xxl-auto {
        margin-right: auto !important
    }
    .mb-xxl-0 {
        margin-bottom: 0 !important
    }
    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }
    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }
    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }
    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-xxl-5 {
        margin-bottom: 3rem !important
    }
    .mb-xxl-6 {
        margin-bottom: 4.5rem !important
    }
    .mb-xxl-7 {
        margin-bottom: 6rem !important
    }
    .mb-xxl-auto {
        margin-bottom: auto !important
    }
    .ms-xxl-0 {
        margin-left: 0 !important
    }
    .ms-xxl-1 {
        margin-left: .25rem !important
    }
    .ms-xxl-2 {
        margin-left: .5rem !important
    }
    .ms-xxl-3 {
        margin-left: 1rem !important
    }
    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }
    .ms-xxl-5 {
        margin-left: 3rem !important
    }
    .ms-xxl-6 {
        margin-left: 4.5rem !important
    }
    .ms-xxl-7 {
        margin-left: 6rem !important
    }
    .ms-xxl-auto {
        margin-left: auto !important
    }
    .m-xxl-n1 {
        margin: -.25rem !important
    }
    .m-xxl-n2 {
        margin: -.5rem !important
    }
    .m-xxl-n3 {
        margin: -1rem !important
    }
    .m-xxl-n4 {
        margin: -1.5rem !important
    }
    .m-xxl-n5 {
        margin: -3rem !important
    }
    .m-xxl-n6 {
        margin: -4.5rem !important
    }
    .m-xxl-n7 {
        margin: -6rem !important
    }
    .mx-xxl-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }
    .mx-xxl-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }
    .mx-xxl-n3 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }
    .mx-xxl-n4 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }
    .mx-xxl-n5 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }
    .mx-xxl-n6 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }
    .mx-xxl-n7 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }
    .my-xxl-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }
    .my-xxl-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }
    .my-xxl-n3 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }
    .my-xxl-n4 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }
    .my-xxl-n5 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }
    .my-xxl-n6 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }
    .my-xxl-n7 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }
    .mt-xxl-n1 {
        margin-top: -.25rem !important
    }
    .mt-xxl-n2 {
        margin-top: -.5rem !important
    }
    .mt-xxl-n3 {
        margin-top: -1rem !important
    }
    .mt-xxl-n4 {
        margin-top: -1.5rem !important
    }
    .mt-xxl-n5 {
        margin-top: -3rem !important
    }
    .mt-xxl-n6 {
        margin-top: -4.5rem !important
    }
    .mt-xxl-n7 {
        margin-top: -6rem !important
    }
    .me-xxl-n1 {
        margin-right: -.25rem !important
    }
    .me-xxl-n2 {
        margin-right: -.5rem !important
    }
    .me-xxl-n3 {
        margin-right: -1rem !important
    }
    .me-xxl-n4 {
        margin-right: -1.5rem !important
    }
    .me-xxl-n5 {
        margin-right: -3rem !important
    }
    .me-xxl-n6 {
        margin-right: -4.5rem !important
    }
    .me-xxl-n7 {
        margin-right: -6rem !important
    }
    .mb-xxl-n1 {
        margin-bottom: -.25rem !important
    }
    .mb-xxl-n2 {
        margin-bottom: -.5rem !important
    }
    .mb-xxl-n3 {
        margin-bottom: -1rem !important
    }
    .mb-xxl-n4 {
        margin-bottom: -1.5rem !important
    }
    .mb-xxl-n5 {
        margin-bottom: -3rem !important
    }
    .mb-xxl-n6 {
        margin-bottom: -4.5rem !important
    }
    .mb-xxl-n7 {
        margin-bottom: -6rem !important
    }
    .ms-xxl-n1 {
        margin-left: -.25rem !important
    }
    .ms-xxl-n2 {
        margin-left: -.5rem !important
    }
    .ms-xxl-n3 {
        margin-left: -1rem !important
    }
    .ms-xxl-n4 {
        margin-left: -1.5rem !important
    }
    .ms-xxl-n5 {
        margin-left: -3rem !important
    }
    .ms-xxl-n6 {
        margin-left: -4.5rem !important
    }
    .ms-xxl-n7 {
        margin-left: -6rem !important
    }
    .p-xxl-0 {
        padding: 0 !important
    }
    .p-xxl-1 {
        padding: .25rem !important
    }
    .p-xxl-2 {
        padding: .5rem !important
    }
    .p-xxl-3 {
        padding: 1rem !important
    }
    .p-xxl-4 {
        padding: 1.5rem !important
    }
    .p-xxl-5 {
        padding: 3rem !important
    }
    .p-xxl-6 {
        padding: 4.5rem !important
    }
    .p-xxl-7 {
        padding: 6rem !important
    }
    .px-xxl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .px-xxl-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }
    .px-xxl-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }
    .px-xxl-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }
    .px-xxl-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }
    .px-xxl-5 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }
    .px-xxl-6 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }
    .px-xxl-7 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }
    .py-xxl-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }
    .py-xxl-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }
    .py-xxl-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }
    .py-xxl-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }
    .py-xxl-4 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }
    .py-xxl-5 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }
    .py-xxl-6 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }
    .py-xxl-7 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }
    .pt-xxl-0 {
        padding-top: 0 !important
    }
    .pt-xxl-1 {
        padding-top: .25rem !important
    }
    .pt-xxl-2 {
        padding-top: .5rem !important
    }
    .pt-xxl-3 {
        padding-top: 1rem !important
    }
    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }
    .pt-xxl-5 {
        padding-top: 3rem !important
    }
    .pt-xxl-6 {
        padding-top: 4.5rem !important
    }
    .pt-xxl-7 {
        padding-top: 6rem !important
    }
    .pe-xxl-0 {
        padding-right: 0 !important
    }
    .pe-xxl-1 {
        padding-right: .25rem !important
    }
    .pe-xxl-2 {
        padding-right: .5rem !important
    }
    .pe-xxl-3 {
        padding-right: 1rem !important
    }
    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }
    .pe-xxl-5 {
        padding-right: 3rem !important
    }
    .pe-xxl-6 {
        padding-right: 4.5rem !important
    }
    .pe-xxl-7 {
        padding-right: 6rem !important
    }
    .pb-xxl-0 {
        padding-bottom: 0 !important
    }
    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }
    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }
    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }
    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-xxl-5 {
        padding-bottom: 3rem !important
    }
    .pb-xxl-6 {
        padding-bottom: 4.5rem !important
    }
    .pb-xxl-7 {
        padding-bottom: 6rem !important
    }
    .ps-xxl-0 {
        padding-left: 0 !important
    }
    .ps-xxl-1 {
        padding-left: .25rem !important
    }
    .ps-xxl-2 {
        padding-left: .5rem !important
    }
    .ps-xxl-3 {
        padding-left: 1rem !important
    }
    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }
    .ps-xxl-5 {
        padding-left: 3rem !important
    }
    .ps-xxl-6 {
        padding-left: 4.5rem !important
    }
    .ps-xxl-7 {
        padding-left: 6rem !important
    }
    .gap-xxl-0 {
        gap: 0 !important
    }
    .gap-xxl-1 {
        gap: .25rem !important
    }
    .gap-xxl-2 {
        gap: .5rem !important
    }
    .gap-xxl-3 {
        gap: 1rem !important
    }
    .gap-xxl-4 {
        gap: 1.5rem !important
    }
    .gap-xxl-5 {
        gap: 3rem !important
    }
    .gap-xxl-6 {
        gap: 4.5rem !important
    }
    .gap-xxl-7 {
        gap: 6rem !important
    }
    .text-xxl-start {
        text-align: left !important
    }
    .text-xxl-end {
        text-align: right !important
    }
    .text-xxl-center {
        text-align: center !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }
    .d-print-inline-block {
        display: inline-block !important
    }
    .d-print-block {
        display: block !important
    }
    .d-print-grid {
        display: grid !important
    }
    .d-print-table {
        display: table !important
    }
    .d-print-table-row {
        display: table-row !important
    }
    .d-print-table-cell {
        display: table-cell !important
    }
    .d-print-flex {
        display: flex !important
    }
    .d-print-inline-flex {
        display: inline-flex !important
    }
    .d-print-none {
        display: none !important
    }
}

.avatar {
    height: 40px;
    width: 40px
}

.avatar-lg {
    height: 64px;
    width: 64px
}

.avatar-title {
    align-items: center;
    color: #3b7ddd;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}

.btn .feather {
    height: 14px;
    width: 14px
}

.btn-danger,
.btn-danger.disabled,
.btn-danger.focus,
.btn-danger.hover:not(:disabled):not(.disabled),
.btn-danger:disabled,
.btn-danger:focus,
.btn-danger:hover:not(:disabled):not(.disabled),
.btn-dark,
.btn-dark.disabled,
.btn-dark.focus,
.btn-dark.hover:not(:disabled):not(.disabled),
.btn-dark:disabled,
.btn-dark:focus,
.btn-dark:hover:not(:disabled):not(.disabled),
.btn-info,
.btn-info.disabled,
.btn-info.focus,
.btn-info.hover:not(:disabled):not(.disabled),
.btn-info:disabled,
.btn-info:focus,
.btn-info:hover:not(:disabled):not(.disabled),
.btn-light,
.btn-light.disabled,
.btn-light.focus,
.btn-light.hover:not(:disabled):not(.disabled),
.btn-light:disabled,
.btn-light:focus,
.btn-light:hover:not(:disabled):not(.disabled),
.btn-primary,
.btn-primary.disabled,
.btn-primary.focus,
.btn-primary.hover:not(:disabled):not(.disabled),
.btn-primary:disabled,
.btn-primary:focus,
.btn-primary:hover:not(:disabled):not(.disabled),
.btn-secondary,
.btn-secondary.disabled,
.btn-secondary.focus,
.btn-secondary.hover:not(:disabled):not(.disabled),
.btn-secondary:disabled,
.btn-secondary:focus,
.btn-secondary:hover:not(:disabled):not(.disabled),
.btn-success,
.btn-success.disabled,
.btn-success.focus,
.btn-success.hover:not(:disabled):not(.disabled),
.btn-success:disabled,
.btn-success:focus,
.btn-success:hover:not(:disabled):not(.disabled),
.btn-warning,
.btn-warning.disabled,
.btn-warning.focus,
.btn-warning.hover:not(:disabled):not(.disabled),
.btn-warning:disabled,
.btn-warning:focus,
.btn-warning:hover:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle,
.show>.btn-dark.dropdown-toggle,
.show>.btn-info.dropdown-toggle,
.show>.btn-light.dropdown-toggle,
.show>.btn-primary.dropdown-toggle,
.show>.btn-secondary.dropdown-toggle,
.show>.btn-success.dropdown-toggle,
.show>.btn-warning.dropdown-toggle {
    color: #fff
}

.btn-facebook {
    --bs-btn-color: #fff;
    --bs-btn-bg: #3b5998;
    --bs-btn-border-color: #3b5998;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #324c81;
    --bs-btn-hover-border-color: #2f477a;
    --bs-btn-focus-shadow-rgb: 88, 114, 167;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2f477a;
    --bs-btn-active-border-color: #2c4372;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #3b5998;
    --bs-btn-disabled-border-color: #3b5998
}

.btn-facebook,
.btn-facebook.disabled,
.btn-facebook.focus,
.btn-facebook.hover:not(:disabled):not(.disabled),
.btn-facebook:disabled,
.btn-facebook:focus,
.btn-facebook:hover:not(:disabled):not(.disabled),
.show>.btn-facebook.dropdown-toggle {
    color: #fff
}

.btn-twitter {
    --bs-btn-color: #000;
    --bs-btn-bg: #1da1f2;
    --bs-btn-border-color: #1da1f2;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #3faff4;
    --bs-btn-hover-border-color: #34aaf3;
    --bs-btn-focus-shadow-rgb: 25, 137, 206;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #4ab4f5;
    --bs-btn-active-border-color: #34aaf3;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #1da1f2;
    --bs-btn-disabled-border-color: #1da1f2
}

.btn-twitter,
.btn-twitter.disabled,
.btn-twitter.focus,
.btn-twitter.hover:not(:disabled):not(.disabled),
.btn-twitter:disabled,
.btn-twitter:focus,
.btn-twitter:hover:not(:disabled):not(.disabled),
.show>.btn-twitter.dropdown-toggle {
    color: #fff
}

.btn-google {
    --bs-btn-color: #fff;
    --bs-btn-bg: #dc4e41;
    --bs-btn-border-color: #dc4e41;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bb4237;
    --bs-btn-hover-border-color: #b03e34;
    --bs-btn-focus-shadow-rgb: 225, 105, 94;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b03e34;
    --bs-btn-active-border-color: #a53b31;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc4e41;
    --bs-btn-disabled-border-color: #dc4e41
}

.btn-google,
.btn-google.disabled,
.btn-google.focus,
.btn-google.hover:not(:disabled):not(.disabled),
.btn-google:disabled,
.btn-google:focus,
.btn-google:hover:not(:disabled):not(.disabled),
.show>.btn-google.dropdown-toggle {
    color: #fff
}

.btn-youtube {
    --bs-btn-color: #fff;
    --bs-btn-bg: red;
    --bs-btn-border-color: red;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #d90000;
    --bs-btn-hover-border-color: #c00;
    --bs-btn-focus-shadow-rgb: 255, 38, 38;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #c00;
    --bs-btn-active-border-color: #bf0000;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: red;
    --bs-btn-disabled-border-color: red
}

.btn-youtube,
.btn-youtube.disabled,
.btn-youtube.focus,
.btn-youtube.hover:not(:disabled):not(.disabled),
.btn-youtube:disabled,
.btn-youtube:focus,
.btn-youtube:hover:not(:disabled):not(.disabled),
.show>.btn-youtube.dropdown-toggle {
    color: #fff
}

.btn-vimeo {
    --bs-btn-color: #000;
    --bs-btn-bg: #1ab7ea;
    --bs-btn-border-color: #1ab7ea;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #3cc2ed;
    --bs-btn-hover-border-color: #31beec;
    --bs-btn-focus-shadow-rgb: 22, 156, 199;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #48c5ee;
    --bs-btn-active-border-color: #31beec;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #1ab7ea;
    --bs-btn-disabled-border-color: #1ab7ea
}

.btn-vimeo,
.btn-vimeo.disabled,
.btn-vimeo.focus,
.btn-vimeo.hover:not(:disabled):not(.disabled),
.btn-vimeo:disabled,
.btn-vimeo:focus,
.btn-vimeo:hover:not(:disabled):not(.disabled),
.show>.btn-vimeo.dropdown-toggle {
    color: #fff
}

.btn-dribbble {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ea4c89;
    --bs-btn-border-color: #ea4c89;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #c74174;
    --bs-btn-hover-border-color: #bb3d6e;
    --bs-btn-focus-shadow-rgb: 237, 103, 155;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #bb3d6e;
    --bs-btn-active-border-color: #b03967;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ea4c89;
    --bs-btn-disabled-border-color: #ea4c89
}

.btn-dribbble,
.btn-dribbble.disabled,
.btn-dribbble.focus,
.btn-dribbble.hover:not(:disabled):not(.disabled),
.btn-dribbble:disabled,
.btn-dribbble:focus,
.btn-dribbble:hover:not(:disabled):not(.disabled),
.show>.btn-dribbble.dropdown-toggle {
    color: #fff
}

.btn-github {
    --bs-btn-color: #fff;
    --bs-btn-bg: #181717;
    --bs-btn-border-color: #181717;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #141414;
    --bs-btn-hover-border-color: #131212;
    --bs-btn-focus-shadow-rgb: 59, 58, 58;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #131212;
    --bs-btn-active-border-color: #121111;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #181717;
    --bs-btn-disabled-border-color: #181717
}

.btn-github,
.btn-github.disabled,
.btn-github.focus,
.btn-github.hover:not(:disabled):not(.disabled),
.btn-github:disabled,
.btn-github:focus,
.btn-github:hover:not(:disabled):not(.disabled),
.show>.btn-github.dropdown-toggle {
    color: #fff
}

.btn-instagram {
    --bs-btn-color: #fff;
    --bs-btn-bg: #e4405f;
    --bs-btn-border-color: #e4405f;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #c23651;
    --bs-btn-hover-border-color: #b6334c;
    --bs-btn-focus-shadow-rgb: 232, 93, 119;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b6334c;
    --bs-btn-active-border-color: #ab3047;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #e4405f;
    --bs-btn-disabled-border-color: #e4405f
}

.btn-instagram,
.btn-instagram.disabled,
.btn-instagram.focus,
.btn-instagram.hover:not(:disabled):not(.disabled),
.btn-instagram:disabled,
.btn-instagram:focus,
.btn-instagram:hover:not(:disabled):not(.disabled),
.show>.btn-instagram.dropdown-toggle {
    color: #fff
}

.btn-pinterest {
    --bs-btn-color: #fff;
    --bs-btn-bg: #bd081c;
    --bs-btn-border-color: #bd081c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #a10718;
    --bs-btn-hover-border-color: #970616;
    --bs-btn-focus-shadow-rgb: 199, 45, 62;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #970616;
    --bs-btn-active-border-color: #8e0615;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #bd081c;
    --bs-btn-disabled-border-color: #bd081c
}

.btn-pinterest,
.btn-pinterest.disabled,
.btn-pinterest.focus,
.btn-pinterest.hover:not(:disabled):not(.disabled),
.btn-pinterest:disabled,
.btn-pinterest:focus,
.btn-pinterest:hover:not(:disabled):not(.disabled),
.show>.btn-pinterest.dropdown-toggle {
    color: #fff
}

.btn-flickr {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0063dc;
    --bs-btn-border-color: #0063dc;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0054bb;
    --bs-btn-hover-border-color: #004fb0;
    --bs-btn-focus-shadow-rgb: 38, 122, 225;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #004fb0;
    --bs-btn-active-border-color: #004aa5;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0063dc;
    --bs-btn-disabled-border-color: #0063dc
}

.btn-flickr,
.btn-flickr.disabled,
.btn-flickr.focus,
.btn-flickr.hover:not(:disabled):not(.disabled),
.btn-flickr:disabled,
.btn-flickr:focus,
.btn-flickr:hover:not(:disabled):not(.disabled),
.show>.btn-flickr.dropdown-toggle {
    color: #fff
}

.btn-bitbucket {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0052cc;
    --bs-btn-border-color: #0052cc;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0046ad;
    --bs-btn-hover-border-color: #0042a3;
    --bs-btn-focus-shadow-rgb: 38, 108, 212;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0042a3;
    --bs-btn-active-border-color: #003e99;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0052cc;
    --bs-btn-disabled-border-color: #0052cc
}

.btn-bitbucket,
.btn-bitbucket.disabled,
.btn-bitbucket.focus,
.btn-bitbucket.hover:not(:disabled):not(.disabled),
.btn-bitbucket:disabled,
.btn-bitbucket:focus,
.btn-bitbucket:hover:not(:disabled):not(.disabled),
.show>.btn-bitbucket.dropdown-toggle {
    color: #fff
}

.btn-light,
.btn-light.disabled,
.btn-light.focus,
.btn-light.hover:not(:disabled):not(.disabled),
.btn-light:disabled,
.btn-light:focus,
.btn-light:hover:not(:disabled):not(.disabled),
.btn-white,
.btn-white.disabled,
.btn-white.focus,
.btn-white.hover:not(:disabled):not(.disabled),
.btn-white:disabled,
.btn-white:focus,
.btn-white:hover:not(:disabled):not(.disabled),
.show>.btn-light.dropdown-toggle,
.show>.btn-white.dropdown-toggle {
    color: #343a40
}

.card {
    box-shadow: 0 0 .875rem 0 rgba(33, 37, 41, .05);
    margin-bottom: 24px
}

.card-header {
    border-bottom-width: 1px
}

.card-title {
    color: #939ba2;
    font-size: .925rem;
    font-weight: 600
}

.card-subtitle {
    font-weight: 400
}

.card-img,
.card-img-bottom,
.card-img-top {
    height: auto;
    max-width: 100%
}

@media (-ms-high-contrast:none) {
    .card-img,
    .card-img-bottom,
    .card-img-top {
        height: 100%
    }
}

.card>.table>tbody tr:last-child td:first-child,
.card>.table>tbody tr:last-child th:first-child {
    border-bottom-left-radius: .25rem
}

.card>.table>tbody tr:last-child td:last-child,
.card>.table>tbody tr:last-child th:last-child {
    border-bottom-right-radius: .25rem
}

.chart {
    margin: auto;
    min-height: 300px;
    position: relative;
    width: 100%
}

.chart-xs {
    min-height: 200px
}

.chart-sm {
    min-height: 252px
}

.chart-lg {
    min-height: 350px
}

.chart-xl {
    min-height: 500px
}

.chart canvas {
    max-width: 100%
}

.content {
    direction: ltr;
    flex: 1;
    max-width: 100vw;
    padding: 1.5rem 1.5rem .75rem;
    width: 100vw
}

@media(min-width:768px) {
    .content {
        max-width: auto;
        width: auto
    }
}

@media(min-width:992px) {
    .content {
        padding: 3rem 3rem 1.5rem
    }
}

.navbar-nav .dropdown-menu {
    box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .05)
}

.dropdown .dropdown-menu.show {
    -webkit-animation-duration: .25s;
    animation-duration: .25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-name: dropdownAnimation;
    animation-name: dropdownAnimation;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}

@-webkit-keyframes dropdownAnimation {
    0% {
        opacity: 0;
        transform: translateY(-8px)
    }
    to {
        opacity: 1;
        transform: translate(0)
    }
}

@keyframes dropdownAnimation {
    0% {
        opacity: 0;
        transform: translateY(-8px)
    }
    to {
        opacity: 1;
        transform: translate(0)
    }
}

.dropdown-toggle:after {
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg)
}

.dropdown-item {
    transition: background .1s ease-in-out, color .1s ease-in-out
}

.dropdown-menu {
    top: auto
}

.dropdown-menu-lg {
    min-width: 20rem
}

.dropdown .list-group .list-group-item {
    border-width: 0 0 1px;
    margin-bottom: 0
}

.dropdown .list-group .list-group-item:first-child,
.dropdown .list-group .list-group-item:last-child {
    border-radius: 0
}

.dropdown .list-group .list-group-item:hover {
    background: #f8f9fa
}

.dropdown-menu-header {
    border-bottom: 1px solid #dee2e6;
    font-weight: 600;
    padding: .75rem;
    text-align: center
}

.dropdown-menu-footer {
    display: block;
    font-size: .75rem;
    padding: .5rem;
    text-align: center
}

.feather {
    stroke-width: 2;
    height: 18px;
    width: 18px
}

.feather-sm {
    height: 14px;
    width: 14px
}

.feather-lg {
    height: 36px;
    width: 36px
}

footer.footer {
    background: #fff;
    direction: ltr;
    padding: 1rem .875rem
}

footer.footer ul {
    margin-bottom: 0
}

@media(max-width:767.98px) {
    footer.footer {
        width: 100vw
    }
}

.hamburger,
.hamburger:after,
.hamburger:before {
    background: #495057;
    border-radius: 1px;
    content: "";
    cursor: pointer;
    display: block;
    height: 3px;
    transition: background .1s ease-in-out, color .1s ease-in-out;
    width: 24px
}

.hamburger {
    position: relative
}

.hamburger:before {
    position: absolute;
    top: -7.5px;
    width: 24px
}

.hamburger:after {
    bottom: -7.5px;
    position: absolute;
    width: 16px
}

.sidebar-toggle:hover .hamburger,
.sidebar-toggle:hover .hamburger:after,
.sidebar-toggle:hover .hamburger:before {
    background: #3b7ddd
}

.hamburger-right,
.hamburger-right:after,
.hamburger-right:before {
    right: 0
}

a.list-group-item {
    text-decoration: none
}

.main {
    background: #f5f7fb;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 0;
    overflow: hidden;
    transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
    width: 100%
}

.navbar {
    border-bottom: 0;
    box-shadow: 0 0 2rem 0 rgba(33, 37, 41, .1)
}

@media(max-width:767.98px) {
    .navbar {
        width: 100vw
    }
}

.navbar .avatar {
    margin-bottom: -15px;
    margin-top: -15px
}

.navbar-nav {
    direction: ltr
}

.navbar-align {
    margin-left: auto
}

.navbar-bg {
    background: #fff
}

.navbar-brand {
    color: #f8f9fa;
    display: block;
    font-size: 1.15rem;
    font-weight: 400;
    padding: .875rem 0
}

.navbar-brand .feather,
.navbar-brand svg {
    color: #3b7ddd;
    height: 24px;
    margin-left: -.15rem;
    margin-right: .375rem;
    margin-top: -.375rem;
    width: 24px
}

.nav-flag,
.nav-icon {
    color: #6c757d;
    display: block;
    font-size: 1.5rem;
    line-height: 1.4;
    padding: .1rem .8rem;
    transition: background .1s ease-in-out, color .1s ease-in-out
}

.nav-flag:after,
.nav-icon:after {
    display: none !important
}

.nav-flag.active,
.nav-flag:hover,
.nav-icon.active,
.nav-icon:hover {
    color: #3b7ddd
}

.nav-flag .feather,
.nav-flag svg,
.nav-icon .feather,
.nav-icon svg {
    height: 20px;
    width: 20px
}

.nav-item .indicator {
    background: #3b7ddd;
    border-radius: 50%;
    box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .05);
    color: #fff;
    display: block;
    font-size: .675rem;
    height: 18px;
    padding: 1px;
    position: absolute;
    right: -8px;
    text-align: center;
    top: 0;
    transition: top .1s ease-out;
    width: 18px
}

.nav-item:hover .indicator {
    top: -4px
}

.nav-item a:focus {
    outline: 0
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .navbar .avatar {
        max-height: 47px
    }
}

@media(max-width:575.98px) {
    .navbar {
        padding: .75rem
    }
    .nav-icon {
        padding: .1rem .75rem
    }
    .dropdown,
    .dropleft,
    .dropright,
    .dropup {
        position: inherit
    }
    .navbar-expand .navbar-nav .dropdown-menu-lg {
        min-width: 100%
    }
    .nav-item .nav-link:after {
        display: none
    }
}

.nav-flag img {
    border-radius: 50%;
    height: 20px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 20px
}

.navbar input {
    direction: ltr
}

#root,
body,
html {
    height: 100%
}

body {
    opacity: 1 !important;
    overflow-y: scroll
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    html {
        overflow-x: hidden
    }
}

.sidebar {
    direction: ltr;
    max-width: 260px;
    min-width: 260px
}

.sidebar,
.sidebar-content {
    background: #123a64;
    transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100vh
}

.sidebar-nav {
    flex-grow: 1;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

}

.sidebar-link,
a.sidebar-link {
    background: #123a64;
    border-left: 3px solid transparent;
    color: rgba(233, 236, 239, .5);
    cursor: pointer;
    display: block;
    font-weight: 400;
    padding: .625rem 1.625rem;
    position: relative;
    text-decoration: none;
    transition: background .1s ease-in-out
}

.sidebar-link i,
.sidebar-link svg,
a.sidebar-link i,
a.sidebar-link svg {
    color: rgba(233, 236, 239, .5);
    margin-right: .75rem
}

.sidebar-link:focus {
    outline: 0
}

.sidebar-link:hover {
    background: #123a64;
    border-left-color: transparent
}

.sidebar-link:hover,
.sidebar-link:hover i,
.sidebar-link:hover svg {
    color: rgba(233, 236, 239, .75)
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-item.active>.sidebar-link {
    background: linear-gradient(90deg, rgba(59, 125, 221, .1), rgba(59, 125, 221, .088) 50%, transparent);
    border-left-color: #3b7ddd;
    color: #e9ecef
}

.sidebar-item.active .sidebar-link:hover i,
.sidebar-item.active .sidebar-link:hover svg,
.sidebar-item.active>.sidebar-link i,
.sidebar-item.active>.sidebar-link svg {
    color: #e9ecef
}

.sidebar-brand {
    color: #f8f9fa;
    display: block;
    font-size: 1.15rem;
    font-weight: 600;
    padding: 1.15rem 1.5rem
}

.sidebar-brand:hover {
    color: #f8f9fa;
    text-decoration: none
}

.sidebar-brand:focus {
    outline: 0
}

.sidebar-toggle {
    cursor: pointer;
    display: flex;
    height: 26px;
    width: 26px
}

.sidebar.collapsed {
    margin-left: -260px
}

@media(min-width:1px)and (max-width:991.98px) {
    .sidebar {
        margin-left: -260px
    }
    .sidebar.collapsed {
        margin-left: 0
    }
}

.sidebar-toggle {
    margin-right: 1rem
}

.sidebar-header {
    background: transparent;
    color: #ced4da;
    font-size: .75rem;
    padding: 1.5rem 1.5rem .375rem
}

.sidebar-badge {
    position: absolute;
    right: 15px;
    top: 14px;
    z-index: 1
}

.sidebar-cta-content {
    background: #2b3947;
    border-radius: .3rem;
    color: #e9ecef;
    margin: 1.75rem;
    padding: 1.5rem
}

.min-vw-50 {
    min-width: 50vw !important
}

.min-vh-50 {
    min-height: 50vh !important
}

.vw-50 {
    width: 50vw !important
}

.vh-50 {
    height: 50vh !important
}

.stat {
    align-items: center;
    background: #d3e2f7;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px
}

.stat svg {
    stroke-width: 1.5;
    color: #3b7ddd !important;
    height: 18px;
    width: 18px
}

.table>:not(:last-child)>:last-child>*,
.table tbody,
.table td,
.table tfoot,
.table th,
.table thead,
.table tr {
    border-color: var(--bs-border-color)
}

.table>tbody>tr>td {
    vertical-align: middle
}

.text-sm {
    font-size: .75rem
}

.text-lg {
    font-size: .925rem
}

b,
strong {
    font-weight: 600
}

pre.snippet {
    word-wrap: break-word;
    text-align: justify;
    white-space: pre-wrap
}

a {
    cursor: pointer
}

.wrapper {
    align-items: stretch;
    background: #123a64;
    display: flex;
    width: 100%;
}

.cursor-grab {
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab
}

.cursor-pointer {
    cursor: pointer
}

svg {
    touch-action: none
}

.jvm-zoomin,
.jvm-zoomout,
image,
text {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.jvm-container {
    height: 100%;
    overflow: hidden;
    position: relative;
    touch-action: none;
    width: 100%
}

.jvm-tooltip {
    background-color: #5c5cff;
    border-radius: 3px;
    box-shadow: 1px 2px 12px rgba(0, 0, 0, .2);
    color: #fff;
    display: none;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px 5px;
    position: absolute;
    white-space: nowrap
}

.jvm-tooltip.active {
    display: block
}

.jvm-zoom-btn {
    background-color: #292929;
    border-radius: 3px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    height: 15px;
    left: 10px;
    line-height: 10px;
    padding: 3px;
    position: absolute;
    width: 15px
}

.jvm-zoom-btn.jvm-zoomout {
    top: 30px
}

.jvm-zoom-btn.jvm-zoomin {
    top: 10px
}

.jvm-series-container {
    position: absolute;
    right: 15px
}

.jvm-series-container.jvm-series-h {
    bottom: 15px
}

.jvm-series-container.jvm-series-v {
    top: 15px
}

.jvm-series-container .jvm-legend {
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-radius: .25rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
    float: left;
    margin-left: .75rem;
    padding: .6rem
}

.jvm-series-container .jvm-legend .jvm-legend-title {
    border-bottom: 1px solid #e5e7eb;
    line-height: 1;
    margin-bottom: .575rem;
    padding-bottom: .5rem;
    text-align: left
}

.jvm-series-container .jvm-legend .jvm-legend-inner {
    overflow: hidden
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick {
    min-width: 40px;
    overflow: hidden
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick:not(:first-child) {
    margin-top: .575rem
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-sample {
    border-radius: 4px;
    float: left;
    height: 16px;
    margin-right: .65rem;
    width: 16px
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-text {
    float: left;
    font-size: 12px;
    text-align: center
}

.jvm-line[animation=true] {
    -webkit-animation: jvm-line-animation 10s linear infinite forwards;
    animation: jvm-line-animation 10s linear infinite forwards
}

@-webkit-keyframes jvm-line-animation {
    0% {
        stroke-dashoffset: 250
    }
}

@keyframes jvm-line-animation {
    0% {
        stroke-dashoffset: 250
    }
}

[data-simplebar] {
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative
}

.simplebar-wrapper {
    height: inherit;
    max-height: inherit;
    max-width: inherit;
    overflow: hidden;
    width: inherit
}

.simplebar-mask {
    direction: inherit;
    height: auto !important;
    overflow: visible;
    width: auto !important;
    z-index: 0
}

.simplebar-mask,
.simplebar-offset {
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0
}

.simplebar-offset {
    -webkit-overflow-scrolling: touch;
    box-sizing: inherit !important;
    direction: inherit !important;
    resize: none !important
}

.simplebar-content-wrapper {
    -ms-overflow-style: none;
    box-sizing: border-box !important;
    direction: inherit;
    display: block;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    scrollbar-width: none;
    width: auto
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    height: 0;
    width: 0
}

.simplebar-content:after,
.simplebar-content:before {
    content: " ";
    display: table
}

.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
    width: 100%
}

.simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    flex-basis: 0;
    flex-grow: inherit;
    flex-shrink: 0;
    float: left;
    height: 100%;
    margin: 0;
    max-height: 1px;
    max-width: 1px;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: relative;
    width: 100%;
    z-index: -1
}

.simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    height: 1000%;
    left: 0;
    min-height: 1px;
    min-width: 1px;
    opacity: 0;
    top: 0;
    width: 1000%;
    z-index: -1
}

.simplebar-height-auto-observer,
.simplebar-track {
    overflow: hidden;
    pointer-events: none;
    position: absolute
}

.simplebar-track {
    bottom: 0;
    right: 0;
    z-index: 1
}

[data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all
}

.simplebar-scrollbar {
    left: 0;
    min-height: 10px;
    position: absolute;
    right: 0
}

.simplebar-scrollbar:before {
    background: #000;
    border-radius: 7px;
    content: "";
    left: 2px;
    opacity: 0;
    position: absolute;
    right: 2px;
    transition: opacity .2s linear
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: .5;
    transition: opacity 0s linear
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    bottom: 2px;
    top: 2px
}

.simplebar-track.simplebar-horizontal {
    height: 11px;
    left: 0
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 7px;
    left: 0;
    min-height: 0;
    min-width: 10px;
    right: auto;
    top: 2px;
    width: auto
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    left: 0;
    right: auto
}

.hs-dummy-scrollbar-size {
    direction: rtl;
    height: 500px;
    opacity: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    position: fixed;
    visibility: hidden;
    width: 500px
}

.simplebar-hide-scrollbar {
    -ms-overflow-style: none;
    left: 0;
    overflow-y: scroll;
    position: fixed;
    scrollbar-width: none;
    visibility: hidden
}

.flatpickr-calendar {
    -webkit-animation: none;
    animation: none;
    background: transparent;
    background: #fff;
    border: 0;
    border-radius: 5px;
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, .08);
    box-sizing: border-box;
    direction: ltr;
    display: none;
    font-size: 14px;
    line-height: 24px;
    opacity: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    touch-action: manipulation;
    visibility: hidden;
    width: 307.875px
}

.flatpickr-calendar.inline,
.flatpickr-calendar.open {
    max-height: 640px;
    opacity: 1;
    visibility: visible
}

.flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999
}

.flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
    animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1)
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px)
}

.flatpickr-calendar.static.open {
    display: block;
    z-index: 999
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
    box-shadow: none !important
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6
}

.flatpickr-calendar .hasTime .dayContainer,
.flatpickr-calendar .hasWeeks .dayContainer {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0
}

.flatpickr-calendar.hasTime .flatpickr-time {
    border-top: 1px solid #e6e6e6;
    height: 40px
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto
}

.flatpickr-calendar:after,
.flatpickr-calendar:before {
    border: solid transparent;
    content: "";
    display: block;
    height: 0;
    left: 22px;
    pointer-events: none;
    position: absolute;
    width: 0
}

.flatpickr-calendar.arrowRight:after,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.rightMost:before {
    left: auto;
    right: 22px
}

.flatpickr-calendar.arrowCenter:after,
.flatpickr-calendar.arrowCenter:before {
    left: 50%;
    right: 50%
}

.flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px
}

.flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px
}

.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
    bottom: 100%
}

.flatpickr-calendar.arrowTop:before {
    border-bottom-color: #e6e6e6
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #fff
}

.flatpickr-calendar.arrowBottom:after,
.flatpickr-calendar.arrowBottom:before {
    top: 100%
}

.flatpickr-calendar.arrowBottom:before {
    border-top-color: #e6e6e6
}

.flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff
}

.flatpickr-calendar:focus {
    outline: 0
}

.flatpickr-wrapper {
    display: inline-block;
    position: relative
}

.flatpickr-months {
    display: flex
}

.flatpickr-months .flatpickr-month {
    background: transparent;
    flex: 1;
    line-height: 1;
    overflow: hidden;
    position: relative;
    text-align: center
}

.flatpickr-months .flatpickr-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
    fill: rgba(0, 0, 0, .9);
    color: rgba(0, 0, 0, .9);
    height: 34px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
    cursor: pointer;
    padding: 10px;
    position: absolute;
    text-decoration: none;
    top: 0;
    z-index: 3
}

.flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
    display: none
}

.flatpickr-months .flatpickr-next-month i,
.flatpickr-months .flatpickr-prev-month i {
    position: relative
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    left: 0
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
    right: 0
}

.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
    color: #959ea9
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #f64747
}

.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
    height: 14px;
    width: 14px
}

.flatpickr-months .flatpickr-next-month svg path,
.flatpickr-months .flatpickr-prev-month svg path {
    fill: inherit;
    transition: fill .1s
}

.numInputWrapper {
    height: auto;
    position: relative
}

.numInputWrapper input,
.numInputWrapper span {
    display: inline-block
}

.numInputWrapper input {
    width: 100%
}

.numInputWrapper input::-ms-clear {
    display: none
}

.numInputWrapper input::-webkit-inner-spin-button,
.numInputWrapper input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.numInputWrapper span {
    border: 1px solid rgba(57, 57, 57, .15);
    box-sizing: border-box;
    cursor: pointer;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    padding: 0 4px 0 2px;
    position: absolute;
    right: 0;
    width: 14px
}

.numInputWrapper span:hover {
    background: rgba(0, 0, 0, .1)
}

.numInputWrapper span:active {
    background: rgba(0, 0, 0, .2)
}

.numInputWrapper span:after {
    content: "";
    display: block;
    position: absolute
}

.numInputWrapper span.arrowUp {
    border-bottom: 0;
    top: 0
}

.numInputWrapper span.arrowUp:after {
    border-bottom: 4px solid rgba(57, 57, 57, .6);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    top: 26%
}

.numInputWrapper span.arrowDown {
    top: 50%
}

.numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(57, 57, 57, .6);
    top: 40%
}

.numInputWrapper span svg {
    height: auto;
    width: inherit
}

.numInputWrapper span svg path {
    fill: rgba(0, 0, 0, .5)
}

.numInputWrapper:hover {
    background: rgba(0, 0, 0, .05)
}

.numInputWrapper:hover span {
    opacity: 1
}

.flatpickr-current-month {
    color: inherit;
    display: inline-block;
    font-size: 135%;
    font-weight: 300;
    height: 34px;
    left: 12.5%;
    line-height: inherit;
    line-height: 1;
    padding: 7.48px 0 0;
    position: absolute;
    text-align: center;
    transform: translateZ(0);
    width: 75%
}

.flatpickr-current-month span.cur-month {
    color: inherit;
    display: inline-block;
    font-family: inherit;
    font-weight: 700;
    margin-left: .5ch;
    padding: 0
}

.flatpickr-current-month span.cur-month:hover {
    background: rgba(0, 0, 0, .05)
}

.flatpickr-current-month .numInputWrapper {
    display: inline-block;
    width: 6ch;
    width: 7ch\0
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: rgba(0, 0, 0, .9)
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: rgba(0, 0, 0, .9)
}

.flatpickr-current-month input.cur-year {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: 0;
    padding: 0 0 0 .5ch;
    vertical-align: initial
}

.flatpickr-current-month input.cur-year:focus {
    outline: 0
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
    background: transparent;
    color: rgba(0, 0, 0, .5);
    font-size: 100%;
    pointer-events: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    background: transparent;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: -1px 0 0;
    outline: none;
    padding: 0 0 0 .5ch;
    position: relative;
    vertical-align: initial;
    width: auto
}

.flatpickr-current-month .flatpickr-monthDropdown-months:active,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
    outline: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: rgba(0, 0, 0, .05)
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: transparent;
    outline: none;
    padding: 0
}

.flatpickr-weekdays {
    align-items: center;
    background: transparent;
    display: flex;
    height: 28px;
    overflow: hidden;
    text-align: center;
    width: 100%
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1
}

span.flatpickr-weekday {
    background: transparent;
    color: rgba(0, 0, 0, .54);
    cursor: default;
    display: block;
    flex: 1;
    font-size: 90%;
    font-weight: bolder;
    line-height: 1;
    margin: 0;
    text-align: center
}

.dayContainer,
.flatpickr-weeks {
    padding: 1px 0 0
}

.flatpickr-days {
    align-items: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    width: 307.875px
}

.flatpickr-days:focus {
    outline: 0
}

.dayContainer {
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-around;
    max-width: 307.875px;
    min-width: 307.875px;
    opacity: 1;
    outline: 0;
    padding: 0;
    text-align: left;
    transform: translateZ(0);
    width: 307.875px
}

.dayContainer+.dayContainer {
    box-shadow: -1px 0 0 #e6e6e6
}

.flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    box-sizing: border-box;
    color: #393939;
    cursor: pointer;
    display: inline-block;
    flex-basis: 14.2857143%;
    font-weight: 400;
    height: 39px;
    justify-content: center;
    line-height: 39px;
    margin: 0;
    max-width: 39px;
    position: relative;
    text-align: center;
    width: 14.2857143%
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
    background: #e6e6e6;
    border-color: #e6e6e6;
    cursor: pointer;
    outline: 0
}

.flatpickr-day.today {
    border-color: #959ea9
}

.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
    background: #959ea9;
    border-color: #959ea9;
    color: #fff
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
    background: #569ff7;
    border-color: #569ff7;
    box-shadow: none;
    color: #fff
}

.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange {
    border-radius: 50px 0 0 50px
}

.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
    border-radius: 0 50px 50px 0
}

.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)) {
    box-shadow: -10px 0 0 #569ff7
}

.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange {
    border-radius: 50px
}

.flatpickr-day.inRange {
    border-radius: 0;
    box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.prevMonthDay {
    background: transparent;
    border-color: transparent;
    color: rgba(57, 57, 57, .3);
    cursor: default
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
    color: rgba(57, 57, 57, .1);
    cursor: not-allowed
}

.flatpickr-day.week.selected {
    border-radius: 0;
    box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7
}

.flatpickr-day.hidden {
    visibility: hidden
}

.rangeMode .flatpickr-day {
    margin-top: 1px
}

.flatpickr-weekwrapper {
    float: left
}

.flatpickr-weekwrapper .flatpickr-weeks {
    box-shadow: 1px 0 0 #e6e6e6;
    padding: 0 12px
}

.flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    line-height: 28px;
    width: 100%
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
    background: transparent;
    border: none;
    color: rgba(57, 57, 57, .3);
    cursor: default;
    display: block;
    max-width: none;
    width: 100%
}

.flatpickr-innerContainer {
    box-sizing: border-box;
    display: block;
    display: flex;
    overflow: hidden
}

.flatpickr-rContainer {
    box-sizing: border-box;
    display: inline-block;
    padding: 0
}

.flatpickr-time {
    box-sizing: border-box;
    display: block;
    display: flex;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    outline: 0;
    overflow: hidden;
    text-align: center
}

.flatpickr-time:after {
    clear: both;
    content: "";
    display: table
}

.flatpickr-time .numInputWrapper {
    flex: 1;
    float: left;
    height: 40px;
    width: 40%
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #393939
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #393939
}

.flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%
}

.flatpickr-time.time24hr .numInputWrapper {
    width: 49%
}

.flatpickr-time input {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: #393939;
    font-size: 14px;
    height: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center
}

.flatpickr-time input.flatpickr-hour {
    font-weight: 700
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    font-weight: 400
}

.flatpickr-time input:focus {
    border: 0;
    outline: 0
}

.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
    align-self: center;
    color: #393939;
    float: left;
    font-weight: 700;
    height: inherit;
    line-height: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 2%
}

.flatpickr-time .flatpickr-am-pm {
    cursor: pointer;
    font-weight: 400;
    outline: 0;
    text-align: center;
    width: 18%
}

.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time input:hover {
    background: #eee
}

.flatpickr-input[readonly] {
    cursor: pointer
}

@-webkit-keyframes fpFadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -20px, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes fpFadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -20px, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.flatpickr-calendar.inline {
    background: transparent;
    box-shadow: none;
    width: 100%
}

.flatpickr-calendar.inline .flatpickr-days {
    width: 100%
}

.flatpickr-calendar.inline .dayContainer {
    max-width: 100%;
    min-width: 100%;
    width: 100%
}

.flatpickr-calendar.inline .flatpickr-day {
    border-radius: .2rem;
    height: 45px;
    line-height: 45px;
    max-width: inherit
}

.flatpickr-calendar.inline .flatpickr-day.today {
    border: 0
}

.flatpickr-calendar.inline .flatpickr-day.today:before {
    border-color: rgba(0, 0, 0, .2) transparent #3b7ddd;
    border-style: solid;
    border-width: 0 0 7px 7px;
    bottom: 4px;
    content: "";
    display: inline-block;
    position: absolute;
    right: 4px
}

.flatpickr-calendar.inline .flatpickr-day.today.selected:before {
    border-color: rgba(0, 0, 0, .2) transparent #fff
}

.flatpickr-calendar.inline .flatpickr-day.today:hover {
    background: #e6e6e6;
    color: #000
}

.flatpickr-calendar.inline .flatpickr-day.selected,
.flatpickr-calendar.inline .flatpickr-day.selected:focus,
.flatpickr-calendar.inline .flatpickr-day.selected:hover {
    background: #3b7ddd;
    border-radius: .2rem;
    color: #fff
}

.flatpickr-calendar.inline .flatpickr-weekdays {
    height: 45px
}

.flatpickr-calendar.inline .flatpickr-weekday {
    height: 45px;
    line-height: 45px
}

.flatpickr-calendar.inline .flatpickr-months .flatpickr-month,
.flatpickr-calendar.inline .flatpickr-months .flatpickr-next-month,
.flatpickr-calendar.inline .flatpickr-months .flatpickr-prev-month {
    height: 45px
}

.flatpickr-calendar.inline .flatpickr-current-month {
    height: 45px;
    line-height: 45px;
    padding-top: 0
}

.flatpickr-calendar.inline .flatpickr-current-month .flatpickr-monthDropdown-months {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.flatpickr-calendar.inline .flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-calendar.inline .flatpickr-current-month input.cur-year {
    font-size: 1.09375rem;
    font-weight: 400
}

.flatpickr-calendar.inline .flatpickr-next-month,
.flatpickr-calendar.inline .flatpickr-prev-month {
    border-radius: .2rem;
    width: 45px
}

.flatpickr-calendar.inline .flatpickr-next-month:hover,
.flatpickr-calendar.inline .flatpickr-prev-month:hover {
    background: #e6e6e6;
    color: #000
}

.simplebar-scrollbar:before {
    background: #fff
}

.simplebar-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-bottom: 0 !important
}

[data-simplebar] {
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 260px
}


.mycoding
{
    font-size:1.29em;
    background:#f1f6f8;
    padding:20px;
    max-width:750px;
    margin-top:25px;
    margin-bottom: 25px;
    border-radius:3px
   
}

.mycoding code {  color: #222 !important }
.my-max-dim-1000 { max-width: 1000px;  }
.copied-thing { padding:10px 15px; color: green; background: #e8ffea; max-width:120px }
.roundedNumber  { padding: 1px 5px; border-radius: 50%; color: white; background:#999 }
.money-1 { font-size: 1.25em; color:green; font-weight: 800; }


.black-card h3, .black-card h4, .black-card p
{
    color: white
}

.form-text
{
    font-size:14px
}

.btn-color{
    background-color: #0d6efd;
    color: #fff;
    border:3px solid rgb(137, 181, 226);
    padding-top:10px;
    padding-bottom:10px
    
  }

  .border-radius
  {
    border-radius: 15px !important;
  }
  
  .profile-image-pic{
    height: 200px;
    width: 200px;
    object-fit: cover;
  }
  
  
 
  a{
    text-decoration: none;
  }

  #login-form1 input[type=text],   #login-form1 input[type=password]
  {
    padding:10px
  }




.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .alert-heading {
    color: inherit;
  }
  
  .alert-link {
    font-weight: 700;
  }
  
  .alert-dismissible {
    padding-right: 3rem;
  }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
  }
  
  .alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
  }
  .alert-primary .alert-link {
    color: #06357a;
  }
  
  .alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8;
  }
  .alert-secondary .alert-link {
    color: #34383c;
  }
  
  .alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
  }
  .alert-success .alert-link {
    color: #0c4128;
  }
  
  .alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
  }
  .alert-info .alert-link {
    color: #04414d;
  }
  
  .alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
  }
  .alert-warning .alert-link {
    color: #523e02;
  }
  
  .alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }
  .alert-danger .alert-link {
    color: #6a1a21;
  }
  
  .alert-light {
    color: #636464;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }
  .alert-light .alert-link {
    color: #4f5050;
  }
  
  .alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf;
  }
  .alert-dark .alert-link {
    color: #101214;
  }


  .infoNumber
  {
    font-size: 2.5em;
    font-weight: bold;
  }

  .bold-text-stats
  {
    font-size: 1.2em
  }

  .subtitle-1
  {
    margin-bottom: 15px;
    font-size:1.3em;
    font-weight: bold;
  }


  .customer-ticket-row
  {
    border-bottom:1px solid #ddd;
    background: white;
    padding-top:15px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .customer-ticket-row:hover
  {
    background:#f2f2f2
  }

  .ticketNameRound
  {
    color: white;
    background: #555;
    padding: 8px 8px;
    border-radius: 50%;
    text-align: center;
    width: 37px;
    font-weight: bold;
  }

  .border-round{ border-radius: 50% }
  .my-edit-field
  {
    border:1px solid #ddd !important;
    padding: 25px !important;
    border-radius:5px !important; 
  }

  .edit-agent-underline
  {
    border-bottom:1px solid #ddd;
    margin-bottom:25px;
    padding-bottom:15px;
    font-weight:bold
  }


  @media(min-width:1300px)  {

    .padding-important-25-desk
    {
        padding:45px !important
    }
    

  }

  .form-switch label
  {
        font-size:15px;
        font-weight: 400
  }

  .big-button 
  {
    padding: 13px 35px !important;
    font-weight: bold
  }


  .not-active-traffic td 
  {
    color: #999
  }

  .active-item-traffic td
  {
    color: #000;
    
  }

  .avatar-123
  {
    width: 45px;
    height:45px
  }


  #chat3 .form-control {
    border-color: transparent;
    }
    
    #chat3 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
    }
    
    .badge-dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-left: 2.9rem;
    margin-top: -.75rem;
    }


    .user-list-chat-row
    {
        border-bottom:1px solid #eee;
        padding-bottom:10px;
        margin-bottom:10px
    }


    .sr-input, .sr-select, input[type="text"], input[type="password"]
    {
        border:1px solid #ccc !important;
        border-radius: 7px !important
    }

    .btn-color
    {
        border-radius: 20px !important
    }

    .heading-ttl
    {
        font-family: "Radio Canada Big";
        font-weight: 700;
    }

    .simpleList
    {
        padding:0;
        margin:0;
        list-style-type: none;
    }

    .simpleList li
    {

        padding-right: 3px;
        display: inline-block;

    }

    .bnRadius
    {
        border-radius: 3px;
    }
    .btn-white {
        background: #fff; /* White background */
        color: #bd239e; /* Text color */
        border: 1px solid #dedede; /* Border color */
        border-radius: 3px; /* Border radius */
        padding: 6px 12px; /* Padding */
        box-sizing: border-box; /* Box sizing */
        transition: all 0.3s ease; /* Transition effect */
      }
      
      .btn-white:hover {
        background: #f8f8f8; /* Lighter background color on hover */
        border-color: #ccc; /* Lighter border color on hover */
        color: #9d236e; /* Darker text color on hover */
      }


    .tooltip-container {
        position: relative;
      }
      
      .tooltip-new {
        position: absolute;
        top: 45px; /* Adjust as needed */
        left: 50%;
        transform: translateX(-8%);
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        padding: 10px;
        z-index: 999; /* Ensure the tooltip is above other elements */
      }
      
      .tooltip-content {
        /* Add styling to the content of the tooltip */
        min-width: 260px;
      }

      a.btn-ai-idea
      {
        padding:6px;
        border-radius: 3px;
        width: 100%;
        box-sizing: border-box;
        background:none
      }

      a.btn-ai-idea:hover
      {
        background:#fefefe !important;
        border:none;
        text-decoration: none;
        color:#333
      }

      .coloredIcon
      {
        color: #dc3545
      }
      .coloredIcon2
      {
        color: burlywood
      }

      .small_txt
      {
        font-size:13px
      }



      .btn-primary-custom {
        background-color: #054283;
        background-image: linear-gradient(to bottom, #0d65c2, #054283);
        border: 2px solid #117ef4;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Drop shadow */
        color: #fff; /* Text color */
        transition: all 0.3s ease; /* Transition effect */
        border-radius:5px;
      }
      
      .btn-primary-custom:hover {
        background-color: #0056b3; /* Darker background color on hover */
        border-color: #004080; /* Darker border color on hover */
        color: white;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Larger drop shadow on hover */
      }


      .btn-orange-custom {
        background-color: #fd7e14; /* Fallback color */
        background-image: linear-gradient(to bottom, #fd7e14, #e55d04); /* Gradient */
        border: 2px solid #e55d04; /* Border color */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Drop shadow */
        color: #fff; /* Text color */
        transition: all 0.3s ease; /* Transition effect */
        border-radius: 5px; /* Border radius */
     
        box-sizing: border-box; /* Box sizing */
      }
      
      .btn-orange-custom:hover {
        background-color: #e55d04; /* Darker background color on hover */
        border-color: #c24903; /* Darker border color on hover */
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Larger drop shadow on hover */
      }
      

      .btn-grey-custom {
        background-color: #6c757d; /* Fallback color */
        background-image: linear-gradient(to bottom, #6c757d, #495057); /* Gradient */
        border: 2px solid #495057; /* Border color */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Drop shadow */
        color: #fff; /* Text color */
        transition: all 0.3s ease; /* Transition effect */
        border-radius: 5px; /* Border radius */
        box-sizing: border-box; /* Box sizing */
      }
      
      .btn-grey-custom:hover {
        background-color: #495057; /* Darker background color on hover */
        border-color: #343a40; /* Darker border color on hover */
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Larger drop shadow on hover */
        color: #aaa
      }