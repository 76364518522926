.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.changeAClass:hover
{
  text-decoration: none;
}

.user-list-chat-row
{
  padding:10px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: none
}

.scrollable-div {
  height: calc(100vh - 400px); /* Adjust the height as needed */
  overflow-y: scroll; /* Enables vertical scrolling */
  border: 1px solid #ccc; /* Optional: Adds a border for visibility */
  padding:10px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.user-list-chat-row:hover
{
  background:#f9f9f9
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.messageBg1
{
  border-radius: 15px; background-color: rgba(57, 192, 237,.2);
}

.messageBg2
{
  border-radius: 15px; background-color: #fbfbfb;
}

.messagesAvatar
{
  width: 45px; height: 100%;
}


.messagesAvatar2
{
  width: 40px; height: 40px;
  background: #444;
  color: white;
  border-radius: 50%;
  text-align: center;
}

.ca-ca
{
    margin-top: 9px;
    font-weight: bold;
}

.sub-menu-position
{
  position: absolute;
  bottom:45px;
  left: 0
}

.sub-menu-position ul
{
  list-style-type: none;
}

.sub-menu-position ul li
{
  position: relative;
}

.imageOnSidebar
{
  border-radius: 50%;
  width: 45px;
  height:45px
}

.imageOnSidebarItc
{
  position: absolute;
  cursor: pointer;
  padding:5px;
  border-radius: 6px
}

.imageOnSidebarItc:hover
{
  background-color: #666;
}

.avatarStatus
{ 
  position: absolute;
  width: 11px;
  height:11px;
  background-color: green;
  border-radius: 5px; top:0;
  right:0
}

.avatarStatusSmallRed
{ 
  position: absolute;
  width: 11px;
  height:11px;
  background-color: red;
  border-radius: 5px; top:0;
  right:0
}


.avatarStatusGreen
{ 

  width: 14px;
  height:14px;
  background-color: green;
  border-radius: 12px; top:0;

}

.avatarStatusRed
{ 

  width: 14px;
  height:14px;
  background-color: red;
  border-radius: 12px; top:0;

}

/*--------menu-for-user ---*/

.menu-for-user
{
  min-width: 240px;
  min-height: 140px;
  background: white;

  border-radius: 5px;
  border:1px solid #ddd;
  position: absolute;
  z-index: 99999999;
  left:65px;
  bottom: -40px
}

.menu-for-user-ul
{
  list-style-type: none;
  padding:0;
  margin:0

}

.menu-for-user-ul li
{
  display:block;
  width: 100%;
  padding: 10px;
  border-bottom:1px solid #eee;
  display: flex;
  cursor: pointer;
}

.child-div-user
{
  flex-grow: 1;
}

.menu-for-user-ul li:hover
{
  background:#efefef
}


/*-------------*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
